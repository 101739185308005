import { defineStore } from 'pinia'

type Permission = {
  view: boolean
  add: boolean
  change: boolean
  delete: boolean
  parentview: boolean
}

export type PermissionsState = {
  permissions: Record<string, Permission>
  permissionDeniedField: string
}

export const usePermissionsStore = defineStore({
  id: 'permissions',
  state: (): PermissionsState => ({
    permissions: {},
    permissionDeniedField: ''
  }),
  actions: {
    setPermissions (payload: Record<string, Permission>) {
      if (payload)
        this.permissions = payload
      else {
        console.error('setPermissions(): payload is empty')
        this.permissions = {}
      }
    },
    setPermissionDeniedField (payload: string) {
      this.permissionDeniedField = payload
    }
  },
  getters: {
    getPermissions (): Record<string, Permission> {
      return this.permissions
    },
    getPermissionDeniedField (): string {
      return this.permissionDeniedField
    },
    hasViewPermission () {
      return (permissionName: string) => this.permissions[permissionName]?.view ?? false
    },
    hasAddPermission () {
      return (permissionName: string) => this.permissions[permissionName]?.add ?? false
    },
    hasChangePermission () {
      return (permissionName: string) => this.permissions[permissionName]?.change ?? false
    },
    hasDeletePermission () {
      return (permissionName: string) => this.permissions[permissionName]?.delete ?? false
    }
  }
})