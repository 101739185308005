import { defineStore } from 'pinia'
import { DisplayNameModel } from '@/models/core'

export type FormState = {
  /**
   * Geänderte Felder in der Form, Cards etc.
   *
   * Die geänderten Felder der Haupt-Form einer Ansicht werden unter _mainform['item] abgelegt,
   * um für alle Use-Cases die gleiche Struktur zu bewahren
   */
  changedListItems: Record<string, Record<string|number, { object: any, fields: string[] }>>
  /** Custom-Änderung die nicht in Feldern oder ListenItems darstellbar ist */
  changedCustom: boolean
  /** Form-Elemente die noch geladen werden  */
  loadingItems: string[]

  unsavedChangesWarningVisible: boolean
  documentUploadInProgress: boolean
  documentUploadInProgressDialogVisible: boolean
  /**
   * Darf der unsavedChangesDialog abgebrochen werden?
   * z.B. nach dem Löschen eines Objekts in einer FormCard **muss** refreshed werden
   * @default true
   **/
  canCancel: boolean
  /**
   * Darf der unsavedChangesDialog Änderungen ignorieren?
   * @default true
   */
  canIgnore: boolean

  /** uniqueCardNames von den FormCards, in denen gerade Items selected sind*/
  selectedFormCards: string[]
}

export const useFormStore = defineStore({
  id: 'form',
  state: (): FormState => ({
    changedListItems: {
      _mainform: { item: { object: null, fields: [] } }
    },
    changedCustom: false,

    unsavedChangesWarningVisible: false,
    documentUploadInProgress: false,
    documentUploadInProgressDialogVisible: false,

    loadingItems: [],
    canCancel: true,
    canIgnore: true,

    selectedFormCards: []
  }),
  actions: {
    addChangedFormField (field: string) {
      if (!this.changedListItems._mainform.item.fields.includes(field)) {
        const fields = this.changedListItems._mainform.item.fields
        fields.push(field)
        this.changedListItems._mainform.item.fields = fields
      }
    },
    removeChangedFormField (field: string) {
      const changedFields = this.changedListItems._mainform.item.fields.filter(item => item !== field)
      this.changedListItems._mainform.item.fields = changedFields
    },
    clearChangedFormFields () {
      this.changedListItems['_mainform'] = { item: { object: null, fields: [] } }
    },
    addChangedListItemField (view: string, object: DisplayNameModel, field: string) {
      if (!this.changedListItems[view]) {
        this.changedListItems[view] = {}
      }
      if (!this.changedListItems[view][object.id]) {
        this.changedListItems[view][object.id] = { object: object, fields: [] }
      }
      this.changedListItems[view][object.id].object = object
      if (!this.changedListItems[view][object.id].fields.includes(field)) {
        this.changedListItems[view][object.id].fields.push(field)
      }
    },
    removeChangedListItemField (view: string, id: string, field: string) {
      if (this.changedListItems[view]?.[id]) {
        this.changedListItems[view][id].fields = this.changedListItems[view][id].fields.filter(item => item !== field)
      }
    },
    removeChangedListItem (view: string, id: string|number) {
      if (this.changedListItems[view]) {
        delete this.changedListItems[view][id]
      }
    },
    removeChangedView (view: string) {
      delete this.changedListItems[view]
    },
    clearChangedListItems () {
      for (const view of Object.keys(this.changedListItems)) {
        if (view !== '_mainform') {
          delete this.changedListItems[view]
        }
      }
    },
    setChangedCustom (value: boolean) {
      this.changedCustom = value
    },
    setDocumentUploadInProgress (payload: boolean) {
      this.documentUploadInProgress = payload
    },
    showDocumentUploadInProgressDialog () {
      this.documentUploadInProgressDialogVisible = true
    },
    hideDocumentUploadInProgressDialog () {
      this.documentUploadInProgressDialogVisible = false
    },
    addLoadingItem (item: string) {
      if (!this.loadingItems.includes(item)) {
        this.loadingItems.push(item)
      }
    },
    removeLoadingItem (item: string) {
      this.loadingItems = this.loadingItems.filter(i => i !== item)
    },
    setCanCancel (value: boolean) {
      this.canCancel = value
    },
    setCanIgnore (value: boolean) {
      this.canIgnore = value
    },
    clearAll () {
      this.changedListItems = {
        _mainform: { item: { object: null, fields: [] } }
      }
      this.changedCustom = false
      this.loadingItems = []
      this.unsavedChangesWarningVisible = false
      this.documentUploadInProgress = false
      this.documentUploadInProgressDialogVisible = false
      this.canCancel = true
      this.canIgnore = true
    },
    showUnsavedWarning () {
      this.unsavedChangesWarningVisible = true
    },
    hideUnsavedWarning () {
      this.unsavedChangesWarningVisible = false
    },
    addSelectedFormCard (payload: string) {
      const index = this.selectedFormCards.indexOf(payload)
      if (index == -1) this.selectedFormCards.push(payload)
    },
    removeSelectedFormCard (payload: string) {
      const index = this.selectedFormCards.indexOf(payload)
      if (index !== -1) this.selectedFormCards.splice(index, 1)
    },
    clearSelectedFormCards () {
      this.selectedFormCards = []
    }
  },
  getters: {
    hasChangedData (): boolean {
      if (this.changedCustom)
        return true
      for (const view of Object.keys(this.changedListItems)) {
        if (view === '_mainform') {
          continue
        }
        if (Object.keys(this.changedListItems[view]).length > 0) {
          //View hat geänderte Items
          return true
        }
      }
      //Wurden Felder in der Haupt-Form geändert?
      return this.changedListItems._mainform.item.fields.length > 0
    },
    isLoading (): boolean {
      return this.loadingItems.length > 0
    },
    getChangedFormFields (): string[] {
      return this.changedListItems._mainform.item.fields.slice() //kein pass-by-reference
    },
    getChangedListItems (): any[] {
      const ret: any[] = []
      for(const view of Object.keys(this.changedListItems)) {
        if(view !== '_mainform') {
          for(const id of Object.keys(this.changedListItems[view])) {
            ret.push({
              view: view,
              object: Object.assign({}, this.changedListItems[view][id].object), //kein pass-by-reference
              fields: this.changedListItems[view][id].fields.slice(), //kein pass-by-reference
            })
          }
        }
      }
      return ret
    },
    getChangedListItemFields (): ((view: string, id: string|number) => string[]) {
      return (view: string, id: string|number): string[] => {
        return this.changedListItems[view]?.[id]?.fields || []
      }
    },
    isDocumentUploadInProgress (): boolean {
      return this.documentUploadInProgress
    },
  }
})