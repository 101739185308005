import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { de, it, en } from 'vuetify/locale'

import {
  MODULE_ARBEITSMITTEL_COLOR, MODULE_AUFGABEN_COLOR,
  MODULE_LIEGENSCHAFTEN_COLOR, MODULE_MANAGEMENT_COLOR,
  MODULE_MITARBEITER_COLOR, MODULE_ORGANISATION_COLOR,
  MODULE_PRAEVENTION_COLOR, MODULE_PROJEKTE_COLOR,
  MODULE_SCHULUNGSORGANISATION_COLOR
} from '@/modules/moduleConstants'
import icons from '@/icons'



const ANCHOR_COLOR = 'rgba(0,0,0,.54)'

const sharedColors = {
  anchor: ANCHOR_COLOR,
}

const labComponents = {}
// Im Devmode werden alle Components direkt geladen,
// weil der AutoImport deaktiviert ist um sich die wiederholten Page reloads zu sparen
const preloadedComponents = import.meta.env.DEV ? components : {}
const preloadedDirectives = import.meta.env.DEV ? directives : {}


export default createVuetify({
  components: {
    ...labComponents,
    ...preloadedComponents,
  },
  directives: {
    ...preloadedDirectives
  },
  display: {
    mobileBreakpoint: 'md'
  },
  locale: {
    messages: { de, it, en },
  },
  icons: {
    defaultSet: 'mdi',
    aliases: Object.assign(aliases, icons),
    sets: {
      mdi
    }
  },
  theme: {
    defaultTheme: 'aufgaben',
    variations: {
      colors: ['primary'],
      lighten: 2,
      darken: 2,
    },
    themes: {
      arbeitsmittel: {
        colors: {
          ...sharedColors,
          primary: MODULE_ARBEITSMITTEL_COLOR,
        }
      },
      aufgaben: {
        colors: {
          ...sharedColors,
          primary: MODULE_AUFGABEN_COLOR,
        }
      },
      management: {
        colors: {
          ...sharedColors,
          primary: MODULE_MANAGEMENT_COLOR,
        }
      },
      mitarbeiter: {
        colors: {
          ...sharedColors,
          primary: MODULE_MITARBEITER_COLOR,
        }
      },
      organisation: {
        colors: {
          ...sharedColors,
          primary: MODULE_ORGANISATION_COLOR,
        }
      },
      praevention: {
        colors: {
          ...sharedColors,
          primary: MODULE_PRAEVENTION_COLOR,
        }
      },
      projekte: {
        colors: {
          ...sharedColors,
          primary: MODULE_PROJEKTE_COLOR,
        }
      },
      liegenschaften: {
        colors: {
          ...sharedColors,
          primary: MODULE_LIEGENSCHAFTEN_COLOR,
        }
      },
      schulungsorganisation: {
        colors: {
          ...sharedColors,
          primary: MODULE_SCHULUNGSORGANISATION_COLOR,
        }
      }
    }
  }
})