export default {
  warn: function (msg: any): void {
    // TODO: remote logging
    console.warn(msg)
  },
  error: function (msg: any): void {
    // TODO: remote logging
    console.error(msg)
  },
  info: function (msg: any): void {
    // TODO: remote logging
    console.info(msg)
  },
  debug: function (msg: any): void {
    // TODO: remote logging
    console.debug(msg)
  }
}
