import { defineStore } from 'pinia'

type AppViewState = {
  // ViewName & Title
  uniqueViewName: string
  appBarEnabled: boolean
  title: string
  subtitle: string
  // Suchleisten
  searchQuery: string
  searchCardQuery: Record<string, string>
  showSearchBar: boolean

  // DropZone
  dropZoneEnabled: boolean
  dropZoneUrl: string | null
  // FloatButton
  showFloatButton: boolean
}

/**
 * Im AppView-Store finden sich alle Einstellungen bezüglich globalem Layout und Ansicht wieder,
 * die zu keinen der anderen Stores passen
 */
export const useAppViewStore = defineStore({
  id: 'appView',
  state: (): AppViewState => ({
    // ViewName & Title
    uniqueViewName: '',
    appBarEnabled: true,
    title: '',
    subtitle: '',
    // Suchleisten
    searchQuery: '',
    searchCardQuery: {},
    showSearchBar: false,
    // DropZone
    dropZoneEnabled: false,
    dropZoneUrl: null,
    // FloatButton
    showFloatButton: false,
  }),
  actions: {
    // ViewName & Title
    setUniqueViewName (payload: string) {
      this.uniqueViewName = payload
    },
    setAppBarEnabled(payload: boolean) {
      this.appBarEnabled = payload
    },
    setTitle (payload: string) {
      this.title = payload
    },
    setSubTitle (payload: string) {
      this.subtitle = payload
    },
    // Suchleisten
    setSearchQuery (payload: string) {
      this.searchQuery = payload
    },
    setSearchCardQuery (card: string, payload: string) {
      this.searchCardQuery[card] = payload
    },
    resetSearchCardQueries () {
      this.searchCardQuery = {}
    },
    setShowSearchBar (payload: boolean) {
      this.showSearchBar = payload
    },
    // DropZone
    setDropZone (payload: boolean) {
      this.dropZoneEnabled = payload
    },
    setDropZoneUrl (payload: string) {
      this.dropZoneUrl = payload
    },
    // FloatButton
    setShowFloatButton (payload: boolean) {
      this.showFloatButton = payload
    }
  },
  getters: {
    getSearchCardQuery () {
      return (card: string) => this.searchCardQuery[card]
    }
  }
})