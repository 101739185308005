import { RouteRecordRaw } from 'vue-router'
import { MODULE_AUFGABEN_COLOR } from '@/modules/moduleConstants'

// Berichte
const BerichteList = () => import('@/modules/berichte/BerichteList.vue')
const BerichteForm = () => import('@/modules/berichte/BerichteForm.vue')
const KopfzeilenList = () => import('@/modules/berichte/KopfzeilenList.vue')
const KopfzeilenForm = () => import('@/modules/berichte/KopfzeilenForm.vue')
const FusszeilenList = () => import('@/modules/berichte/FusszeilenList.vue')
const FusszeilenForm = () => import('@/modules/berichte/FusszeilenForm.vue')
const ReportsList = () => import('@/modules/berichte/ReportsList.vue')
const ReportsForm = () => import('@/modules/berichte/ReportsForm.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const routes: RouteRecordRaw[] = [
  // Berichte
  { path: '/berichte/berichte/',
    name: 'BerichteList',
    component: BerichteList,
    props: true,
    meta: { icon: 'mdi-file-chart-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/berichte/berichte/:id',
    name: 'BerichteFormUpdate',
    component: BerichteForm,
    props: updateFormProps,
    meta: { icon: 'mdi-file-chart-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/berichte/globalekopfzeile/',
    name: 'KopfzeilenList',
    component: KopfzeilenList,
    meta: { icon: 'mdi-page-layout-header', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/berichte/globalekopfzeile/add',
    name: 'KopfzeilenForm',
    component: KopfzeilenForm,
    meta: { icon: 'mdi-page-layout-header', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/berichte/globalekopfzeile/:id',
    name: 'KopfzeilenFormUpdate',
    component: KopfzeilenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-page-layout-header', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/berichte/globalefusszeile/',
    name: 'FusszeilenList',
    component: FusszeilenList,
    meta: { icon: 'mdi-page-layout-footer', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/berichte/globalefusszeile/add',
    name: 'FusszeilenForm',
    component: FusszeilenForm,
    meta: { icon: 'mdi-page-layout-footer', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/berichte/globalefusszeile/:id',
    name: 'FusszeilenFormUpdate',
    component: FusszeilenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-page-layout-footer', color: MODULE_AUFGABEN_COLOR }
  },
  // Reports
  { path: '/berichte/reports/',
    name: 'ReportsList',
    component: ReportsList,
    meta: { icon: 'mdi-file-compare', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/berichte/reports/add',
    name: 'ReportsForm',
    component: ReportsForm,
    meta: { icon: 'mdi-file-compare', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/berichte/reports/:id',
    name: 'ReportsFormUpdate',
    component: ReportsForm,
    props: updateFormProps,
    meta: { icon: 'mdi-file-compare', color: MODULE_AUFGABEN_COLOR }
  },
]

export default routes
