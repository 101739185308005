import { RouteRecordRaw } from 'vue-router'
import { MODULE_STAMMDATEN_COLOR } from '@/modules/moduleConstants'

// Stammdaten
const BilderForm = () => import('@/modules/stammdaten/BilderForm.vue')
const BilderList = () => import('@/modules/stammdaten/BilderList.vue')
const AdressenForm = () => import('@/modules/stammdaten/AdressenForm.vue')
const AnredenForm = () => import('@/modules/stammdaten/AnredenForm.vue')
const AnredenList = () => import('@/modules/stammdaten/AnredenList.vue')
const NationalitaetenForm = () => import('@/modules/stammdaten/NationalitaetenForm.vue')
const NationalitaetenList = () => import('@/modules/stammdaten/NationalitaetenList.vue')
const SprachenList = () => import('@/modules/stammdaten/SprachenList.vue')
const SprachenForm = () => import('@/modules/stammdaten/SprachenForm.vue')
const RechtsverweiseForm = () => import('@/modules/stammdaten/RechtsverweiseForm.vue')
const RechtsverweiseList = () => import('@/modules/stammdaten/RechtsverweiseList.vue')
const RechtsverweiseTypenForm = () => import('@/modules/stammdaten/RechtsverweiseTypenForm.vue')
const RechtsverweiseTypenList = () => import('@/modules/stammdaten/RechtsverweiseTypenList.vue')
const RechtsverweiseKategorienForm = () => import('@/modules/stammdaten/RechtsverweiseKategorienForm.vue')
const RechtsverweiseKategorienList = () => import('@/modules/stammdaten/RechtsverweiseKategorienList.vue')
const InhalteForm = () => import('@/modules/stammdaten/InhalteForm.vue')
const DokumenteForm = () => import('@/modules/stammdaten/DokumenteForm.vue')
const DokumenteList = () => import('@/modules/stammdaten/DokumenteList.vue')
const DokumenteFreigegebenList = () => import('@/modules/stammdaten/DokumenteFreigegebenList.vue')
const DokumenteTypenForm = () => import('@/modules/stammdaten/DokumenteTypenForm.vue')
const DokumenteTypenList = () => import('@/modules/stammdaten/DokumenteTypenList.vue')
const AdressenKategorienForm = () => import('@/modules/stammdaten/AdressenKategorienForm.vue')
const AdressenKategorienList = () => import('@/modules/stammdaten/AdressenKategorienList.vue')
const EmailKategorienForm = () => import('@/modules/stammdaten/EmailKategorienForm.vue')
const EmailKategorienList = () => import('@/modules/stammdaten/EmailKategorienList.vue')
const InternetKategorienForm = () => import('@/modules/stammdaten/InternetKategorienForm.vue')
const InternetKategorienList = () => import('@/modules/stammdaten/InternetKategorienList.vue')
const TelefonKategorienForm = () => import('@/modules/stammdaten/TelefonKategorienForm.vue')
const TelefonKategorienList = () => import('@/modules/stammdaten/TelefonKategorienList.vue')
const ChangelogForm = () => import('@/modules/stammdaten/ChangelogForm.vue')
const ChangelogList = () => import('@/modules/stammdaten/ChangelogList.vue')
const DigitaleSignaturenFreigegebenForm = () => import('@/modules/stammdaten/DigitaleSignaturenFreigegebenForm.vue')
const ZusatzM2MFelderWerteList = () => import('@/modules/stammdaten/ZusatzM2MFelderWerteList.vue')
const ZusatzM2MFelderWerteForm = () => import('@/modules/stammdaten/ZusatzM2MFelderWerteForm.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const routes: RouteRecordRaw[] = [
  // Bilder AddForObject
  { path: '/:modul/:model/:objectId/bilder/add',
    name: 'BilderForm',
    component: BilderForm,
    props: true,
    meta: { icon: 'mdi-image-outline', color: MODULE_STAMMDATEN_COLOR }
  },
  // Bilder Object List
  { path: '/:modul/:model/:objectId/bilder/',
    name: 'BilderList',
    component: BilderList,
    props: true,
    meta: { icon: 'mdi-image-outline', color: MODULE_STAMMDATEN_COLOR }
  },
  // Bilder Update
  { path: '/stammdaten/bilder/:id',
    name: 'BilderFormUpdate',
    component: BilderForm,
    props: updateFormProps,
    meta: { icon: 'mdi-image-outline', color: MODULE_STAMMDATEN_COLOR }
  },
  // Adressen Form
  { path: '/stammdaten/adressen/:id',
    name: 'AdressenFormUpdate',
    component: AdressenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-email-outline', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/:modul/:model/:objectId/adressen/add',
    name: 'AdressenForm',
    component: AdressenForm,
    props: true,
    meta: { icon: 'mdi-email-outline', color: MODULE_STAMMDATEN_COLOR }
  },
  // Anreden
  { path: '/stammdaten/anreden/',
    name: 'AnredenList',
    component: AnredenList,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/anreden/add',
    name: 'AnredenForm',
    component: AnredenForm,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/anreden/:id',
    name: 'AnredenFormUpdate',
    component: AnredenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  // Nationalitaeten
  { path: '/stammdaten/nationalitaeten/',
    name: 'NationalitaetenList',
    component: NationalitaetenList,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/nationalitaeten/add',
    name: 'NationalitaetenForm',
    component: NationalitaetenForm,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/nationalitaeten/:id',
    name: 'NationalitaetenFormUpdate',
    component: NationalitaetenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  // Sprachen
  { path: '/stammdaten/sprachen/',
    name: 'SprachenList',
    component: SprachenList,
    meta: { icon: 'mdi-translate', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/sprachen/add',
    name: 'SprachenForm',
    component: SprachenForm,
    meta: { icon: 'mdi-translate', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/sprachen/:id',
    name: 'SprachenFormUpdate',
    component: SprachenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-translate', color: MODULE_STAMMDATEN_COLOR }
  },
  // Rechtsverweise
  { path: '/stammdaten/rechtsverweise/',
    name: 'RechtsverweiseList',
    component: RechtsverweiseList,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/rechtsverweise/add',
    name: 'RechtsverweiseForm',
    component: RechtsverweiseForm,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/rechtsverweise/:id',
    name: 'RechtsverweiseFormUpdate',
    component: RechtsverweiseForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/rechtsverweisetypen/',
    name: 'RechtsverweiseTypenList',
    component: RechtsverweiseTypenList,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/rechtsverweisetypen/add',
    name: 'RechtsverweiseTypenForm',
    component: RechtsverweiseTypenForm,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/rechtsverweisetypen/:id',
    name: 'RechtsverweiseTypenFormUpdate',
    component: RechtsverweiseTypenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/rechtsverweisekategorien/',
    name: 'RechtsverweiseKategorienList',
    component: RechtsverweiseKategorienList,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/rechtsverweisekategorien/add',
    name: 'RechtsverweiseKategorienForm',
    component: RechtsverweiseKategorienForm,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/rechtsverweisekategorien/:id',
    name: 'RechtsverweiseKategorienFormUpdate',
    component: RechtsverweiseKategorienForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  // Inhalte
  { path: '/stammdaten/inhalte/add',
    name: 'InhalteForm',
    component: InhalteForm,
    meta: { icon: 'mdi-calendar-range', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/inhalte/:id',
    name: 'InhalteFormUpdate',
    component: InhalteForm,
    props: updateFormProps,
    meta: { icon: 'mdi-calendar-range', color: MODULE_STAMMDATEN_COLOR }
  },
  // Inhalte AddForObject
  { path: '/:modul/:model/:objectId/inhalte/add',
    name: 'InhalteObjektForm',
    component: InhalteForm,
    props: true,
    meta: { icon: 'mdi-calendar-range', color: MODULE_STAMMDATEN_COLOR }
  },
  // Dokumente
  { path: '/stammdaten/dokumente/',
    name: 'DokumenteList',
    component: DokumenteList,
    props: true,
    meta: { icon: 'mdi-file-document', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/dokumente/:id',
    name: 'DokumenteFormUpdate',
    component: DokumenteForm,
    props: updateFormProps,
    meta: { icon: 'mdi-file-document', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/:modul/:model/:objectId/dokumente/add',
    name: 'DokumenteForm',
    component: DokumenteForm,
    props: true,
    meta: { icon: 'mdi-file-document', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/:modul/:model/:objectId/dokumente/',
    name: 'DokumenteListMobile',
    component: DokumenteList,
    props: true,
    meta: { icon: 'mdi-file-document', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/freigegebenedokumente/:hash/',
    name: 'DokumenteFreigegebenList',
    component: DokumenteFreigegebenList,
    props: true,
    meta: { requiresAuth: false, icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  // Dokumentetypen
  { path: '/stammdaten/dokumentetypen/',
    name: 'DokumenteTypenList',
    component: DokumenteTypenList,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/dokumentetypen/add',
    name: 'DokumenteTypenForm',
    component: DokumenteTypenForm,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/dokumentetypen/:id',
    name: 'DokumenteTypenFormUpdate',
    component: DokumenteTypenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  // Adressekategorien
  { path: '/stammdaten/adressekategorien/',
    name: 'AdressenKategorienList',
    component: AdressenKategorienList,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/adressekategorien/add',
    name: 'AdressenKategorienForm',
    component: AdressenKategorienForm,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/adressekategorien/:id',
    name: 'AdressenKategorienFormUpdate',
    component: AdressenKategorienForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  // Emailkategorien
  { path: '/stammdaten/emailkategorien/',
    name: 'EmailKategorienList',
    component: EmailKategorienList,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/emailkategorien/add',
    name: 'EmailKategorienForm',
    component: EmailKategorienForm,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/emailkategorien/:id',
    name: 'EmailKategorienFormUpdate',
    component: EmailKategorienForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  // Internetkategorien
  { path: '/stammdaten/internetkategorien/',
    name: 'InternetKategorienList',
    component: InternetKategorienList,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/internetkategorien/add',
    name: 'InternetKategorienForm',
    component: InternetKategorienForm,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/internetkategorien/:id',
    name: 'InternetKategorienFormUpdate',
    component: InternetKategorienForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  // Telefonkategorien
  { path: '/stammdaten/telefonkategorien/',
    name: 'TelefonKategorienList',
    component: TelefonKategorienList,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/telefonkategorien/add',
    name: 'TelefonKategorienForm',
    component: TelefonKategorienForm,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/telefonkategorien/:id',
    name: 'TelefonKategorienFormUpdate',
    component: TelefonKategorienForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  // Changelog
  { path: '/stammdaten/changelog/',
    name: 'ChangelogList',
    component: ChangelogList,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/changelog/add',
    name: 'ChangelogForm',
    component: ChangelogForm,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/changelog/:id',
    name: 'ChangelogFormUpdate',
    component: ChangelogForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/signaturanforderung/:hash/',
    name: 'SignaturenFreigegebenForm',
    component: DigitaleSignaturenFreigegebenForm,
    props: true,
    meta: { requiresAuth: false, icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/freiem2mfelderwerte/',
    name: 'ZusatzM2MFelderWerteList',
    component: ZusatzM2MFelderWerteList,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/freiem2mfelderwerte/add',
    name: 'ZusatzM2MFelderWerteForm',
    component: ZusatzM2MFelderWerteForm,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/stammdaten/freiem2mfelderwerte/:id',
    name: 'ZusatzM2MFelderWerteFormUpdate',
    component: ZusatzM2MFelderWerteForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
]

export default routes
