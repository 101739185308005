import { RouteRecordRaw } from 'vue-router'
import { MODULE_TICKETSYSTEM_COLOR } from '@/modules/moduleConstants'

// Ticketsystem
const KonfigurationenList = () => import('@/modules/ticketsystem/KonfigurationenList.vue')
const KonfigurationenForm = () => import('@/modules/ticketsystem/KonfigurationenForm.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const routes: RouteRecordRaw[] = [
  // Ticketsystem
  { path: '/ticketsystem/konfigurationen/',
    name: 'KonfigurationenList',
    component: KonfigurationenList,
    meta: { icon: '', color: MODULE_TICKETSYSTEM_COLOR }
  },
  { path: '/ticketsystem/konfigurationen/add',
    name: 'KonfigurationenForm',
    component: KonfigurationenForm,
    meta: { icon: '', color: MODULE_TICKETSYSTEM_COLOR }
  },
  { path: '/ticketsystem/konfigurationen/:id',
    name: 'KonfigurationenFormUpdate',
    component: KonfigurationenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_TICKETSYSTEM_COLOR }
  },
]

export default routes
