import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { computed } from 'vue'
import { UseQueryOptions } from '@vue/apollo-composable'

const httpLink = new HttpLink({
  uri: 'http://collector.test/graphql'
})

const authLink = setContext((_, { headers }) => {
  const token =localStorage.getItem('jsonWebToken') || localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    }
  }
})

export const defaultQueryOptions  = computed<UseQueryOptions>(() => ({
  context: {
    headers: {
      'Authorization': localStorage.getItem('jsonWebToken') || localStorage.getItem('token')
    },
    'uri': `${localStorage.getItem('collector_reader_url')}/graphql/`
  },
  fetchPolicy: 'no-cache'
}))

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
})