import { RouteRecordRaw } from 'vue-router'
import { MODULE_ORGANISATION_COLOR } from '@/modules/moduleConstants'

// Organisation
const UnternehmenList = () => import('@/modules/organisation/UnternehmenList.vue')
const UnternehmenMap = () => import('@/modules/organisation/UnternehmenMap.vue')
const UnternehmenForm = () => import('@/modules/organisation/UnternehmenForm.vue')
const StandorteList = () => import('@/modules/organisation/StandorteList.vue')
const StandorteMap = () => import('@/modules/organisation/StandorteMap.vue')
const StandorteForm = () => import('@/modules/organisation/StandorteForm.vue')
const AbteilungenList = () => import('@/modules/organisation/AbteilungenList.vue')
const AbteilungenForm = () => import('@/modules/organisation/AbteilungenForm.vue')
const KundenList = () => import('@/modules/organisation/KundenList.vue')
const KundenMap = () => import('@/modules/organisation/KundenMap.vue')
const KundenForm = () => import('@/modules/organisation/KundenForm.vue')
const AnsprechPersonKundenForm = () => import('@/modules/organisation/AnsprechPersonKundenForm.vue')
const LieferantenList = () => import('@/modules/organisation/LieferantenList.vue')
const LieferantenMap = () => import('@/modules/organisation/LieferantenMap.vue')
const LieferantenForm = () => import('@/modules/organisation/LieferantenForm.vue')
const AnsprechPersonLieferantenForm = () => import('@/modules/organisation/AnsprechPersonLieferantenForm.vue')
const AnsprechPersonBereichList = () => import('@/modules/organisation/AnsprechPersonBereichList.vue')
const AnsprechPersonBereichForm = () => import('@/modules/organisation/AnsprechPersonBereichForm.vue')
const TaetigkeitsbereicheList = () => import('@/modules/organisation/TaetigkeitsbereicheList.vue')
const TaetigkeitsbereicheForm = () => import('@/modules/organisation/TaetigkeitsbereicheForm.vue')
const KollektivvertraegeList = () => import('@/modules/organisation/KollektivvertraegeList.vue')
const KollektivvertraegeForm = () => import('@/modules/organisation/KollektivvertraegeForm.vue')
const RollenbeschreibungenList = () => import('@/modules/organisation/RollenbeschreibungenList.vue')
const RollenbeschreibungenForm = () => import('@/modules/organisation/RollenbeschreibungenForm.vue')
const BrandrisikenForm = () => import('@/modules/organisation/BrandrisikenForm.vue')
const ErsteHilfeKategorienForm = () => import('@/modules/organisation/ErsteHilfeKategorienForm.vue')
const RisikoArt37Form = () => import('@/modules/organisation/RisikoArt37Form.vue')
const AnzahlMitarbeiterList = () => import('@/modules/organisation/AnzahlMitarbeiterList.vue')
const AnzahlMitarbeiterForm = () => import('@/modules/organisation/AnzahlMitarbeiterForm.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const updateFormObjectProps = (route) => {
  return {
    modul: route.params.modul,
    model: route.params.model,
    objectId: parseInt(route.params.objectId)
  }
}

const routes: RouteRecordRaw[] = [
  // Unternehmen
  { path: '/organisation/unternehmen/',
    name: 'UnternehmenList',
    component: UnternehmenList,
    meta: { icon: 'mdi-domain', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/unternehmen/map',
    name: 'UnternehmenMap',
    component: UnternehmenMap,
    meta: { icon: 'mdi-domain', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/unternehmen/add',
    name: 'UnternehmenForm',
    component: UnternehmenForm,
    meta: { icon: 'mdi-domain', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/unternehmen/:id',
    name: 'UnternehmenFormUpdate',
    component: UnternehmenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-domain', color: MODULE_ORGANISATION_COLOR }
  },
  // Standorte
  { path: '/organisation/standorte/',
    name: 'StandorteList',
    component: StandorteList,
    meta: { icon: 'mdi-office-building-marker-outline', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/standorte/map',
    name: 'StandorteMap',
    component: StandorteMap,
    meta: { icon: 'mdi-office-building-marker-outline', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/standorte/add',
    name: 'StandorteForm',
    component: StandorteForm,
    meta: { icon: 'mdi-office-building-marker-outline', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/standorte/:id',
    name: 'StandorteFormUpdate',
    component: StandorteForm,
    props: updateFormProps,
    meta: { icon: 'mdi-office-building-marker-outline', color: MODULE_ORGANISATION_COLOR }
  },
  // Abteilungen
  { path: '/organisation/abteilungen/',
    name: 'AbteilungenList',
    component: AbteilungenList,
    meta: { icon: 'mdi-account-group-outline', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/abteilungen/add',
    name: 'AbteilungenForm',
    component: AbteilungenForm,
    meta: { icon: 'mdi-account-group-outline', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/abteilungen/:id',
    name: 'AbteilungenFormUpdate',
    component: AbteilungenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-account-group-outline', color: MODULE_ORGANISATION_COLOR }
  },
  // Ansprechperson Bereich
  { path: '/organisation/ansprechpersonbereiche/',
    name: 'AnsprechPersonBereichList',
    component: AnsprechPersonBereichList,
    meta: { icon: '', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/ansprechpersonbereiche/add',
    name: 'AnsprechPersonBereichForm',
    component: AnsprechPersonBereichForm,
    meta: { icon: '', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/ansprechpersonbereiche/:id',
    name: 'AnsprechPersonBereichFormUpdate',
    component: AnsprechPersonBereichForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_ORGANISATION_COLOR }
  },
  // Kunden
  { path: '/organisation/kunden/',
    name: 'KundenList',
    component: KundenList,
    meta: { icon: 'mdi-account-tie-outline', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/kunden/add',
    name: 'KundenForm',
    component: KundenForm,
    meta: { icon: 'mdi-account-tie-outline', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/kunden/map',
    name: 'KundenMap',
    component: KundenMap,
    meta: { icon: 'mdi-account-tie-outline', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/kunden/:id',
    name: 'KundenFormUpdate',
    component: KundenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-account-tie-outline', color: MODULE_ORGANISATION_COLOR }
  },
  // Ansprechpersonen Kunden
  { path: '/organisation/ansprechpersonkunden/add',
    name: 'AnsprechPersonKundenForm',
    component: AnsprechPersonKundenForm,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/ansprechpersonkunden/:id',
    name: 'AnsprechPersonKundenFormUpdate',
    component: AnsprechPersonKundenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/:modul/:model/:objectId/ansprechpersonkunden/add',
    name: 'AnsprechPersonKundenObjektForm',
    component: AnsprechPersonKundenForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_ORGANISATION_COLOR }
  },
  // Lieferanten
  { path: '/organisation/lieferanten/',
    name: 'LieferantenList',
    component: LieferantenList,
    meta: { icon: 'mdi-account-hard-hat', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/lieferanten/map',
    name: 'LieferantenMap',
    component: LieferantenMap,
    meta: { icon: 'mdi-account-hard-hat', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/lieferanten/add',
    name: 'LieferantenForm',
    component: LieferantenForm,
    meta: { icon: 'mdi-account-hard-hat', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/lieferanten/:id',
    name: 'LieferantenFormUpdate',
    component: LieferantenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-account-hard-hat', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/ansprechpersonlieferanten/add',
    name: 'AnsprechPersonLieferantenForm',
    component: AnsprechPersonLieferantenForm,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/ansprechpersonlieferanten/:id',
    name: 'AnsprechPersonLieferantenFormUpdate',
    component: AnsprechPersonLieferantenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/:modul/:model/:objectId/ansprechpersonlieferanten/add',
    name: 'AnsprechPersonLieferantenObjektForm',
    component: AnsprechPersonLieferantenForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_ORGANISATION_COLOR }
  },
  // Taetigkeitsbereiche
  { path: '/organisation/taetigkeitsbereiche/',
    name: 'TaetigkeitsbereicheList',
    component: TaetigkeitsbereicheList,
    meta: { icon: '', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/taetigkeitsbereiche/add',
    name: 'TaetigkeitsbereicheForm',
    component: TaetigkeitsbereicheForm,
    meta: { icon: '', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/taetigkeitsbereiche/:id',
    name: 'TaetigkeitsbereicheFormUpdate',
    component: TaetigkeitsbereicheForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_ORGANISATION_COLOR }
  },
  // Kollektivvertraege
  { path: '/organisation/kollektivvertraege/',
    name: 'KollektivvertraegeList',
    component: KollektivvertraegeList,
    meta: { icon: '', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/kollektivvertraege/add',
    name: 'KollektivvertraegeForm',
    component: KollektivvertraegeForm,
    meta: { icon: '', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/kollektivvertraege/:id',
    name: 'KollektivvertraegeFormUpdate',
    component: KollektivvertraegeForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_ORGANISATION_COLOR }
  },
  // Rollenbeschreibungen
  { path: '/organisation/rollenbeschreibungen/',
    name: 'RollenbeschreibungenList',
    component: RollenbeschreibungenList,
    meta: { icon: '', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/rollenbeschreibungen/add',
    name: 'RollenbeschreibungenForm',
    component: RollenbeschreibungenForm,
    meta: { icon: '', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/rollenbeschreibungen/:id',
    name: 'RollenbeschreibungenFormUpdate',
    component: RollenbeschreibungenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_ORGANISATION_COLOR }
  },
  // Brandrisiko
  { path: '/organisation/brandrisiken/:id',
    name: 'BrandrisikoFormUpdate',
    component: BrandrisikenForm,
    meta: { icon: '', color: MODULE_ORGANISATION_COLOR }
  },
  // Erste Hilfe Kategorien
  { path: '/organisation/kategorieerstehilfe/:id',
    name: 'ErsteHilfeKategorienFormUpdate',
    component: ErsteHilfeKategorienForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_ORGANISATION_COLOR }
  },
  // Risiko Art. 37
  { path: '/organisation/risikoart37/:id',
    name: 'RisikoArt37FormUpdate',
    component: RisikoArt37Form,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_ORGANISATION_COLOR }
  },
  // Anzahl Mitarbeiter
  { path: '/organisation/anzahlmitarbeiter/',
    name: 'AnzahlMitarbeiterList',
    component: AnzahlMitarbeiterList,
    meta: { icon: 'mdi-account-group-outline', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/anzahlmitarbeiter/add',
    name: 'AnzahlMitarbeiterForm',
    component: AnzahlMitarbeiterForm,
    meta: { icon: 'mdi-account-group-outline', color: MODULE_ORGANISATION_COLOR }
  },
  { path: '/organisation/anzahlmitarbeiter/:id',
    name: 'AnzahlMitarbeiterFormUpdate',
    component: AnzahlMitarbeiterForm,
    props: updateFormProps,
    meta: { icon: 'mdi-account-group-outline', color: MODULE_ORGANISATION_COLOR }
  },
]

export default routes
