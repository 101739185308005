import { RouteRecordRaw } from 'vue-router'
import { MODULE_AUFGABEN_COLOR } from '@/modules/moduleConstants'

// Verwaltung
const BenutzerList = () => import('@/modules/verwaltung/BenutzerList.vue')
const BenutzerForm = () => import('@/modules/verwaltung/BenutzerForm.vue')
const GruppenList = () => import('@/modules/verwaltung/GruppenList.vue')
const GruppenForm = () => import('@/modules/verwaltung/GruppenForm.vue')
const ThemenList = () => import('@/modules/verwaltung/ThemenList.vue')
const ThemenForm = () => import('@/modules/verwaltung/ThemenForm.vue')
const EmailbenachrichtigungenForm = () => import('@/modules/verwaltung/EmailbenachrichtigungenForm.vue')
const DatenfilterList = () => import('@/modules/verwaltung/DatenfilterList.vue')
const DatenfilterForm = () => import('@/modules/verwaltung/DatenfilterForm.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const updateDetailFormProps = (route) => {
  return {
    objectId: parseInt(route.params.objectId),
    id: parseInt(route.params.id)
  }
}

const addFormObjectProps = (route) => {
  return {
    objectId: parseInt(route.params.objectId)
  }
}

const routes: RouteRecordRaw[] = [
  // Verwaltung
  { path: '/authentication/users/',
    name: 'BenutzerList',
    component: BenutzerList,
    meta: { icon: 'mdi-account-box-multiple-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/authentication/users/add',
    name: 'BenutzerForm',
    component: BenutzerForm,
    meta: { icon: 'mdi-account-box-multiple-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/authentication/users/:id',
    name: 'BenutzerFormUpdate',
    component: BenutzerForm,
    props: updateFormProps,
    meta: { icon: 'mdi-account-box-multiple-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/authentication/groups/',
    name: 'GruppenList',
    component: GruppenList,
    meta: { icon: 'mdi-account-group-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/authentication/groups/add',
    name: 'GruppenForm',
    component: GruppenForm,
    meta: { icon: 'mdi-account-group-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/authentication/groups/:id',
    name: 'GruppenFormUpdate',
    component: GruppenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-account-group-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/verwaltung/themen/',
    name: 'ThemenList',
    component: ThemenList,
    meta: { icon: '', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/verwaltung/themen/add',
    name: 'ThemenForm',
    component: ThemenForm,
    meta: { icon: '', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/verwaltung/themen/:id',
    name: 'ThemenFormUpdate',
    component: ThemenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/authentication/users/:objectId/emailbenachrichtigung/add',
    name: 'EmailbenachrichtigungenForm',
    component: EmailbenachrichtigungenForm,
    props: addFormObjectProps,
    meta: { icon: 'mdi-email', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/authentication/users/:objectId/emailbenachrichtigung/:id',
    name: 'EmailbenachrichtigungenFormUpdate',
    component: EmailbenachrichtigungenForm,
    props: updateDetailFormProps,
    meta: { icon: 'mdi-email', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/datenfilter/datenfilter/',
    name: 'DatenfilterList',
    component: DatenfilterList,
    meta: { icon: 'mdi-database-arrow-down-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/datenfilter/datenfilter/add',
    name: 'DatenfilterForm',
    component: DatenfilterForm,
    meta: { icon: 'mdi-database-arrow-down-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/datenfilter/datenfilter/:id',
    name: 'DatenfilterFormUpdate',
    component: DatenfilterForm,
    props: updateFormProps,
    meta: { icon: 'mdi-database-arrow-down-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/authentication/users/:objectId/datenfilter/add',
    name: 'UserDatenfilterForm',
    component: DatenfilterForm,
    props: addFormObjectProps,
    meta: { icon: 'mdi-database-arrow-down-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/authentication/users/:objectId/datenfilter/:id',
    name: 'UserDatenfilterFormUpdate',
    component: DatenfilterForm,
    props: updateDetailFormProps,
    meta: { icon: 'mdi-database-arrow-down-outline', color: MODULE_AUFGABEN_COLOR }
  },
]

export default routes
