import { defineStore } from 'pinia'
import { RouteLocationNormalized } from 'vue-router'

export type PendingRouteState = {
  pendingRoute: RouteLocationNormalized | null
}

export const usePendingRouteStore = defineStore({
  id: 'pendingRoute',
  state: (): PendingRouteState => ({
    pendingRoute: null
  }),
  actions: {
    setPendingRoute(payload: RouteLocationNormalized) {
      this.pendingRoute = payload
    },
    resetPendingRoute() {
      this.pendingRoute = null
    }
  }
})