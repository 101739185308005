import { defineStore } from 'pinia'

import { ContentLanguage, UILanguage } from '@/types'

export type I18nState = {
  /** Verfügbare UI-Languages */
  uiLanguages: UILanguage[]
  /** Aktive UI-Language */
  uiLanguage: UILanguage
  /** Verfügbare Content-Languages */
  contentLanguages: ContentLanguage[]
  /** Aktive UI-Language */
  contentLanguage: ContentLanguage
}

export const useI18nStore = defineStore({
  id: 'i18n',
  state: (): I18nState => ({
    uiLanguages: ['de', 'it', 'en'],
    uiLanguage: 'de',
    contentLanguages: ['de', 'it', 'en'],
    contentLanguage: 'de'
  }),
  actions: {
    setUiLanguage(lang: UILanguage) {
      this.uiLanguage = lang
    },
    setUiLanguages(langs: UILanguage[]) {
      this.uiLanguages = langs
    },
    setContentLanguage(lang: ContentLanguage) {
      this.contentLanguage = lang
    },
    setContentLanguages(langs: ContentLanguage[]) {
      this.contentLanguages = langs
    }
  },
  getters: {
    getUiLanguage(): UILanguage {
      return this.uiLanguage
    },
    getUiLanguages(): UILanguage[] {
      return this.uiLanguages
    },
    getContentLanguage(): ContentLanguage {
      return this.contentLanguage
    },
    getContentLanguages(): ContentLanguage[] {
      return this.contentLanguages
    }
  }
})