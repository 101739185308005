import { RouteRecordRaw } from 'vue-router'
import { MODULE_PROJEKTE_COLOR } from '@/modules/moduleConstants'

// Projekte
const PosPlanList = () => import('@/modules/projekte/PosPlanList.vue')
const PosPlanMap = () => import('@/modules/projekte/PosPlanMap.vue')
const PosPlanForm = () => import('@/modules/projekte/PosPlanForm.vue')
const ZusatzinformationForm = () => import('@/modules/projekte/ZusatzinformationForm.vue')
const ArbeitsabschnitteForm = () => import('@/modules/projekte/ArbeitsabschnitteForm.vue')
const ArbeitsabschnitteList = () => import('@/modules/projekte/ArbeitsabschnitteList.vue')
const ArbeitsabschnitteGefaehrdungenForm = () => import('@/modules/projekte/ArbeitsabschnitteGefaehrdungenForm.vue')
const ArbeitsabschnitteGruppierungList = () => import('@/modules/projekte/ArbeitsabschnitteGruppierungList.vue')
const ArbeitsabschnitteGruppierungForm = () => import('@/modules/projekte/ArbeitsabschnitteGruppierungForm.vue')
const ArbeitsabschnitteRelationsForm = () => import('@/modules/projekte/ArbeitsabschnitteRelationsForm.vue')
const StandardtexteList = () => import('@/modules/projekte/StandardtexteList.vue')
const StandardtexteForm = () => import('@/modules/projekte/StandardtexteForm.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const updateDetailFormProps = (route) => {
  return {
    objectId: parseInt(route.params.objectId),
    id: parseInt(route.params.id)
  }
}

const routes: RouteRecordRaw[] = [
  // Projekte
  { path: '/projekte/posplan/',
    name: 'ProjekteList',
    component: PosPlanList,
    meta: { icon: 'mdi-poll', color: MODULE_PROJEKTE_COLOR }
  },
  { path: '/projekte/posplan/map',
    name: 'ProjekteMap',
    component: PosPlanMap,
    meta: { icon: 'mdi-poll', color: MODULE_PROJEKTE_COLOR }
  },
  { path: '/projekte/posplan/add',
    name: 'ProjekteForm',
    component: PosPlanForm,
    meta: { icon: 'mdi-poll', color: MODULE_PROJEKTE_COLOR }
  },
  { path: '/projekte/posplan/:id',
    name: 'ProjekteFormUpdate',
    component: PosPlanForm,
    props: updateFormProps,
    meta: { icon: 'mdi-poll', color: MODULE_PROJEKTE_COLOR }
  },
  //   Zusatzinfos
  { path: '/projekte/zusatzinformationen/:id',
    name: 'ZusatzinformationFormUpdate',
    component: ZusatzinformationForm,
    props: updateFormProps,
    meta: { icon: 'mdi-information-outline', color: MODULE_PROJEKTE_COLOR }
  },
  { path: '/projekte/posplan/:objectId/zusatzinformationen/add',
    name: 'ZusatzinformationObjektForm',
    component: ZusatzinformationForm,
    props: true,
    meta: { icon: 'mdi-information-outline', color: MODULE_PROJEKTE_COLOR }
  },
  // Arbeitsabschnitte
  { path: '/projekte/arbeitsabschnitte/',
    name: 'ArbeitsabschnitteList',
    component: ArbeitsabschnitteList,
    meta: { icon: 'mdi-bookmark-outline', color: MODULE_PROJEKTE_COLOR }
  },
  { path: '/projekte/arbeitsabschnitte/add',
    name: 'ArbeitsabschnitteForm',
    component: ArbeitsabschnitteForm,
    meta: { icon: 'mdi-bookmark-outline', color: MODULE_PROJEKTE_COLOR }
  },
  { path: '/projekte/arbeitsabschnitte/:id',
    name: 'ArbeitsabschnitteFormUpdate',
    component: ArbeitsabschnitteForm,
    props: updateFormProps,
    meta: { icon: 'mdi-bookmark-outline', color: MODULE_PROJEKTE_COLOR }
  },
  // Arbeitsabschnitte Gruppierung
  { path: '/projekte/arbeitsabschnittegruppierungen/',
    name: 'ArbeitsabschnitteGruppierungList',
    component: ArbeitsabschnitteGruppierungList,
    meta: { icon: '', color: MODULE_PROJEKTE_COLOR }
  },
  { path: '/projekte/arbeitsabschnittegruppierungen/add',
    name: 'ArbeitsabschnitteGruppierungForm',
    component: ArbeitsabschnitteGruppierungForm,
    meta: { icon: '', color: MODULE_PROJEKTE_COLOR }
  },
  { path: '/projekte/arbeitsabschnittegruppierungen/:id',
    name: 'ArbeitsabschnitteGruppierungFormUpdate',
    component: ArbeitsabschnitteGruppierungForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_PROJEKTE_COLOR }
  },
  { path: '/projekte/posplan/:objectId/arbeitsabschnitterelations/:id',
    name: 'ArbeitsabschnitteRelationsFormUpdate',
    component: ArbeitsabschnitteRelationsForm,
    props: updateDetailFormProps,
    meta: { icon: '', color: MODULE_PROJEKTE_COLOR }
  },
  { path: '/projekte/arbeitsabschnitte/:id/gefaehrdungen/add',
    name: 'ArbeitsabschnitteGefaehrdungenSubForm',
    component: ArbeitsabschnitteGefaehrdungenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-hazard-lights', color: MODULE_PROJEKTE_COLOR }
  },
  { path: '/projekte/gefaehrdungen/add',
    name: 'ArbeitsabschnitteGefaehrdungenForm',
    component: ArbeitsabschnitteGefaehrdungenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-hazard-lights', color: MODULE_PROJEKTE_COLOR }
  },
  { path: '/praevention/gefaehrdungen/:id',
    name: 'ArbeitsabschnitteGefaehrdungenFormUpdate',
    component: ArbeitsabschnitteGefaehrdungenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-hazard-lights', color: MODULE_PROJEKTE_COLOR }
  },
  // Standardtexte
  { path: '/projekte/standardtexte/',
    name: 'StandardtexteList',
    component: StandardtexteList,
    meta: { icon: '', color: MODULE_PROJEKTE_COLOR }
  },
  { path: '/projekte/standardtexte/add',
    name: 'StandardtexteForm',
    component: StandardtexteForm,
    meta: { icon: '', color: MODULE_PROJEKTE_COLOR }
  },
  { path: '/projekte/standardtexte/:id',
    name: 'StandardtexteFormUpdate',
    component: StandardtexteForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_PROJEKTE_COLOR }
  },
]

export default routes
