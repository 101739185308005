import { RouteRecordRaw } from 'vue-router'
import { MODULE_STAMMDATEN_COLOR } from '@/modules/moduleConstants'

// Kosten
const KostenstelleForm = () => import('@/modules/kosten/KostenstelleForm.vue')
const KostenstelleList = () => import('@/modules/kosten/KostenstelleList.vue')
const KostenForm = () => import('@/modules/kosten/KostenForm.vue')
const KostenList = () => import('@/modules/kosten/KostenList.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const routes: RouteRecordRaw[] = [
  // Kosten
  { path: '/kosten/kostenstellen/',
    name: 'KostenstelleList',
    component: KostenstelleList,
    meta: { icon: 'mdi-wallet-outline', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/kosten/kostenstellen/add',
    name: 'KostenstelleForm',
    component: KostenstelleForm,
    meta: { icon: 'mdi-wallet-outline', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/kosten/kostenstellen/:id',
    name: 'KostenstelleFormUpdate',
    component: KostenstelleForm,
    props: updateFormProps,
    meta: { icon: 'mdi-wallet-outline', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/kosten/kosten/',
    name: 'KostenList',
    component: KostenList,
    meta: { icon: 'mdi-currency-eur', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/kosten/kosten/:id',
    name: 'KostenFormUpdate',
    component: KostenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-currency-eur', color: MODULE_STAMMDATEN_COLOR }
  },
  // Kosten AddForObject
  { path: '/:modul/:model/:objectId/kosten/add',
    name: 'KostenObjektForm',
    component: KostenForm,
    props: true,
    meta: { icon: 'mdi-currency-eur', color: MODULE_STAMMDATEN_COLOR }
  },
]

export default routes
