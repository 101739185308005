import { RouteRecordRaw } from 'vue-router'
import { MODULE_LIEGENSCHAFTEN_COLOR } from '@/modules/moduleConstants'

// Liegenschaften
const LiegenschaftenList = () => import('@/modules/liegenschaften/LiegenschaftenList.vue')
const LiegenschaftenMap = () => import('@/modules/liegenschaften/LiegenschaftenMap.vue')
const LiegenschaftenForm = () => import('@/modules/liegenschaften/LiegenschaftenForm.vue')
const GrundparzellenList = () => import('@/modules/liegenschaften/GrundparzellenList.vue')
const GrundparzellenForm = () => import('@/modules/liegenschaften/GrundparzellenForm.vue')
const BauparzellenList = () => import('@/modules/liegenschaften/BauparzellenList.vue')
const BauparzellenMap = () => import('@/modules/liegenschaften/BauparzellenMap.vue')
const BauparzellenForm = () => import('@/modules/liegenschaften/BauparzellenForm.vue')
const KulturgattungenList = () => import('@/modules/liegenschaften/KulturgattungenList.vue')
const KulturgattungenForm = () => import('@/modules/liegenschaften/KulturgattungenForm.vue')
const VerwendungstypenList = () => import('@/modules/liegenschaften/VerwendungstypenList.vue')
const VerwendungstypenForm = () => import('@/modules/liegenschaften/VerwendungstypenForm.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const updateFormObjectProps = (route) => {
  return {
    modul: route.params.modul,
    model: route.params.model,
    objectId: parseInt(route.params.objectId)
  }
}

const routes: RouteRecordRaw[] = [
  // Liegenschaften
  { path: '/liegenschaften/liegenschaften/',
    name: 'LiegenschaftenList',
    component: LiegenschaftenList,
    meta: { icon: 'mdi-home-city-outline', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  { path: '/liegenschaften/liegenschaften/map',
    name: 'LiegenschaftenMap',
    component: LiegenschaftenMap,
    meta: { icon: 'mdi-home-city-outline', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  { path: '/liegenschaften/liegenschaften/add',
    name: 'LiegenschaftenForm',
    component: LiegenschaftenForm,
    meta: { icon: 'mdi-home-city-outline', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  { path: '/liegenschaften/liegenschaften/:id',
    name: 'LiegenschaftenFormUpdate',
    component: LiegenschaftenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-home-city-outline', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  // Grundparzellen
  { path: '/liegenschaften/grundparzellen/',
    name: 'GrundparzellenList',
    component: GrundparzellenList,
    meta: { icon: 'mdi-pine-tree', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  { path: '/liegenschaften/grundparzellen/add',
    name: 'GrundparzellenForm',
    component: GrundparzellenForm,
    meta: { icon: 'mdi-pine-tree', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  { path: '/liegenschaften/grundparzellen/:id',
    name: 'GrundparzellenFormUpdate',
    component: GrundparzellenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-pine-tree', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  { path: '/:modul/:model/:objectId/grundparzellen/add',
    name: 'GrundparzellenObjektForm',
    component: GrundparzellenForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-pine-tree', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  // Bauparzellen
  { path: '/liegenschaften/bauparzellen/',
    name: 'BauparzellenList',
    component: BauparzellenList,
    meta: { icon: 'mdi-office-building', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  { path: '/liegenschaften/bauparzellen/map',
    name: 'BauparzellenMap',
    component: BauparzellenMap,
    meta: { icon: 'mdi-office-building', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  { path: '/liegenschaften/bauparzellen/add',
    name: 'BauparzellenForm',
    component: BauparzellenForm,
    meta: { icon: 'mdi-office-building', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  { path: '/liegenschaften/bauparzellen/:id',
    name: 'BauparzellenFormUpdate',
    component: BauparzellenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-office-building', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  { path: '/:modul/:model/:objectId/bauparzellen/add',
    name: 'BauparzellenObjektForm',
    component: BauparzellenForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-office-building', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  // Kulturgattungen
  { path: '/liegenschaften/kulturgattungen/',
    name: 'KulturgattungenList',
    component: KulturgattungenList,
    meta: { icon: '', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  { path: '/liegenschaften/kulturgattungen/add',
    name: 'KulturgattungenForm',
    component: KulturgattungenForm,
    meta: { icon: '', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  { path: '/liegenschaften/kulturgattungen/:id',
    name: 'KulturgattungenFormUpdate',
    component: KulturgattungenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  // Verwendungstypen
  { path: '/liegenschaften/verwendungstypen/',
    name: 'VerwendungstypenList',
    component: VerwendungstypenList,
    meta: { icon: '', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  { path: '/liegenschaften/verwendungstypen/add',
    name: 'VerwendungstypenForm',
    component: VerwendungstypenForm,
    meta: { icon: '', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
  { path: '/liegenschaften/verwendungstypen/:id',
    name: 'VerwendungstypenFormUpdate',
    component: VerwendungstypenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_LIEGENSCHAFTEN_COLOR }
  },
]

export default routes
