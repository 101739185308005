import { RouteRecordRaw } from 'vue-router'
import { MODULE_ADMINISTRATION_COLOR } from '@/modules/moduleConstants'

// Administration
const ImportsList = () => import('@/modules/administration/ImportsList.vue')
const InstanzenList = () => import('@/modules/administration/InstanzenList.vue')
const LizenzenList = () => import('@/modules/administration/LizenzenList.vue')
const LizenzenForm = () => import('@/modules/administration/LizenzenForm.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/administration/imports/',
    name: 'ImportsList',
    component: ImportsList,
    meta: { icon: 'mdi-database-import-outline', color: MODULE_ADMINISTRATION_COLOR }
  },
  // Instanzen
  { path: '/administration/instanzen/',
    name: 'InstanzenList',
    component: InstanzenList,
    meta: { icon: 'mdi-view-list', color: MODULE_ADMINISTRATION_COLOR }
  },
  // Lizenzen
  { path: '/administration/lizenzen/',
    name: 'LizenzenList',
    component: LizenzenList,
    meta: { icon: 'mdi-view-list', color: MODULE_ADMINISTRATION_COLOR }
  },
  { path: '/administration/lizenzen/add',
    name: 'LizenzenForm',
    component: LizenzenForm,
    meta: { icon: 'mdi-view-list', color: MODULE_ADMINISTRATION_COLOR }
  },
  { path: '/administration/lizenzen/:id',
    name: 'LizenzenFormUpdate',
    component: LizenzenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-view-list', color: MODULE_ADMINISTRATION_COLOR }
  },
]

export default routes
