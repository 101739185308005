<template>
  <v-progress-linear
    v-if="isLoading"
    :model-value="value"
    :height="2"
    color="white"
    style="z-index: 100000"
  />
</template>

<script setup lang="ts">
import { useProgressStore } from '@/stores/progress'
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'

const { isLoading } = storeToRefs(useProgressStore())

const value = ref<number>(0)

/** Erhöht den Wert der Progressbar alle 250ms um 5 % */
const continuousProgress = async () => {
  while (isLoading.value) {
    value.value = value.value + 5
    if (value.value >= 100) {
      value.value = 0
    }
    await new Promise(resolve => setTimeout(resolve, 250));
  }
}

watch(isLoading, (newVal, oldVal) => {
  if(newVal && !oldVal) {
    // Neuer Ladevorgang: setze value auf 0
    value.value = 0
    continuousProgress()
  }
}, { immediate: true })

</script>