import { RouteRecordRaw } from 'vue-router'
import { MODULE_NOTIZEN_COLOR } from '@/modules/moduleConstants'

// Notizen
const NotizenForm = () => import('@/modules/notizen/NotizenForm.vue')
const NotizenList = () => import('@/modules/notizen/NotizenList.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const routes: RouteRecordRaw[] = [
  // Notizen List
  { path: '/notizen/notizen/',
    name: 'NotizenList',
    component: NotizenList,
    meta: { icon: 'mdi-bookmark-outline', color: MODULE_NOTIZEN_COLOR }
  },
  { path: '/notizen/notizen/:id',
    name: 'NotizenFormUpdate',
    component: NotizenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-bookmark-outline', color: MODULE_NOTIZEN_COLOR }
  },
  // Notizen AddForObject
  { path: '/:modul/:model/:objectId/notizen/add',
    name: 'NotizenObjektForm',
    component: NotizenForm,
    props: true,
    meta: { icon: 'mdi-bookmark-outline', color: MODULE_NOTIZEN_COLOR }
  },
  // Notizen Object List
  { path: '/:modul/:model/:objectId/notizen/',
    name: 'NotizenMobileList',
    component: NotizenList,
    props: true,
    meta: { icon: 'mdi-bookmark-outline', color: MODULE_NOTIZEN_COLOR }
  },
]

export default routes
