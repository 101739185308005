import { defineStore } from 'pinia'

/**
 * Aktuell ist dieser Store nur ein 1:1 Replacement für das alte Package vue-progress-bar,
 aber er könnte erweitert werden um Ladebalken etc. allgemein zu vereinheitlichen (z.B. die LoadingItems vom FormStore)
*/

export type ProgressState = {
  loading: boolean
}

export const useProgressStore = defineStore({
  id: 'progress',
  state: (): ProgressState => ({
    loading: false
  }),
  actions: {
    start() {
      this.loading = true
    },
    finish() {
      this.loading = false
    }
  },
  getters: {
    isLoading(): boolean {
      return this.loading
    }
  }
})