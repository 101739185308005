import { RouteRecordRaw } from 'vue-router'
import { MODULE_ARBEITSMITTEL_COLOR } from '@/modules/moduleConstants'

// Arbeitsmittel
const ArbeitsmittelList = () => import('@/modules/arbeitsmittel/ArbeitsmittelList.vue')
const ArbeitsmittelForm = () => import('@/modules/arbeitsmittel/ArbeitsmittelForm.vue')
const ArbeitsmittelMap = () => import('@/modules/arbeitsmittel/ArbeitsmittelMap.vue')
const KategorienList = () => import('@/modules/arbeitsmittel/KategorienList.vue')
const KategorienForm = () => import('@/modules/arbeitsmittel/KategorienForm.vue')
const HauptKategorienList = () => import('@/modules/arbeitsmittel/HauptKategorienList.vue')
const HauptKategorienForm = () => import('@/modules/arbeitsmittel/HauptKategorienForm.vue')
const HerstellerList = () => import('@/modules/arbeitsmittel/HerstellerList.vue')
const HerstellerForm = () => import('@/modules/arbeitsmittel/HerstellerForm.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const routes: RouteRecordRaw[] = [
  // Arbeitsmittel
  { path: '/arbeitsmittel/arbeitsmittel/',
    name: 'ArbeitsmittelList',
    component: ArbeitsmittelList,
    meta: { icon: 'mdi-monitor-cellphone', color: MODULE_ARBEITSMITTEL_COLOR }
  },
  { path: '/arbeitsmittel/arbeitsmittel/add',
    name: 'ArbeitsmittelForm',
    component: ArbeitsmittelForm,
    meta: { icon: 'mdi-monitor-cellphone', color: MODULE_ARBEITSMITTEL_COLOR }
  },
  { path: '/arbeitsmittel/arbeitsmittel/map',
    name: 'ArbeitsmittelMap',
    component: ArbeitsmittelMap,
    meta: { icon: 'mdi-monitor-cellphone', color: MODULE_ARBEITSMITTEL_COLOR }
  },
  { path: '/arbeitsmittel/arbeitsmittel/:id',
    name: 'ArbeitsmittelFormUpdate',
    component: ArbeitsmittelForm,
    props: updateFormProps,
    meta: { icon: 'mdi-monitor-cellphone', color: MODULE_ARBEITSMITTEL_COLOR }
  },
  // HauptKategorien
  { path: '/arbeitsmittel/hauptkategorien/',
    name: 'HauptKategorienList',
    component: HauptKategorienList,
    meta: { icon: '', color: MODULE_ARBEITSMITTEL_COLOR }
  },
  { path: '/arbeitsmittel/hauptkategorien/add',
    name: 'HauptKategorienForm',
    component: HauptKategorienForm,
    meta: { icon: '', color: MODULE_ARBEITSMITTEL_COLOR }
  },
  { path: '/arbeitsmittel/hauptkategorien/:id',
    name: 'HauptKategorienFormUpdate',
    component: HauptKategorienForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_ARBEITSMITTEL_COLOR }
  },
  // Kategorien
  { path: '/arbeitsmittel/kategorien/',
    name: 'KategorienList',
    component: KategorienList,
    meta: { icon: '', color: MODULE_ARBEITSMITTEL_COLOR }
  },
  { path: '/arbeitsmittel/kategorien/add',
    name: 'KategorienForm',
    component: KategorienForm,
    meta: { icon: '', color: MODULE_ARBEITSMITTEL_COLOR }
  },
  { path: '/arbeitsmittel/kategorien/:id',
    name: 'KategorienFormUpdate',
    component: KategorienForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_ARBEITSMITTEL_COLOR }
  },
  // Hersteller
  { path: '/arbeitsmittel/hersteller/',
    name: 'HerstellerList',
    component: HerstellerList,
    meta: { icon: 'mdi-factory', color: MODULE_ARBEITSMITTEL_COLOR }
  },
  { path: '/arbeitsmittel/hersteller/add',
    name: 'HerstellerForm',
    component: HerstellerForm,
    meta: { icon: 'mdi-factory', color: MODULE_ARBEITSMITTEL_COLOR }
  },
  { path: '/arbeitsmittel/hersteller/:id',
    name: 'HerstellerFormUpdate',
    component: HerstellerForm,
    props: updateFormProps,
    meta: { icon: 'mdi-factory', color: MODULE_ARBEITSMITTEL_COLOR }
  },
]

export default routes
