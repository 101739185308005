<template>
  <v-app id="app">
    <base-progress-bar />
    <router-view />
  </v-app>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue'
import { useNavigationStore } from '@/stores/navigation'
import BaseProgressBar from '@/base/BaseAppProgressBar.vue'
import { useRecordNavigationStore } from '@/stores/recordNavigation'
import { useRouter, useRoute } from 'vue-router'
import { usePendingRouteStore } from '@/stores/pendingRoute'

export default defineComponent({
  name: 'App',
  components: { BaseProgressBar },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const { setBrowserBackForwardUsed } = useNavigationStore()
    const recordNavigationStore = useRecordNavigationStore()
    const pendingRouteStore = usePendingRouteStore()

    watch(() => route.query, (query) => {
      // Speziellen Parameter auslesen um die Blaetteraktionen zu ermoeglichen
      if (query?.paging_ids_key) {
        const paging_ids_key = query.paging_ids_key as string
        const jsonRaw = localStorage.getItem(paging_ids_key)
        if (jsonRaw) {
          const paging_data = JSON.parse(jsonRaw)
          if (paging_data) {
            recordNavigationStore.setUniqueViewName(paging_data.uniqueViewName)
            recordNavigationStore.setIds(paging_data.ids)
            recordNavigationStore.setNextUrl(paging_data.nextUrl)
            recordNavigationStore.setPreviousUrl(paging_data.previousUrl)
            recordNavigationStore.setHidden(paging_data.hide)
          }
        }
        localStorage.removeItem(paging_ids_key)
      }

      Object.keys(localStorage).forEach(function(key){
        if (key.startsWith('paging_ids_')) {
          localStorage.removeItem(key)
        }
      })
    })

    return {
      router,
      recordNavigationStore,
      pendingRouteStore,
      setBrowserBackForwardUsed
    }
  },
  data: function () {
    return {}
  },
  created () {
    const d = new Date()
    localStorage.setItem('programDate', `${d.getDate()}-${d.getMonth()+1}-${d.getFullYear()}`)
    window.onpopstate = () => {
      this.setBrowserBackForwardUsed(true)
    }
  },
})
</script>