import { defineStore } from 'pinia'

export type TokenValidationState = {
  tokenValid: boolean
  apiRequestCount: number
}

export const useTokenValidationStore = defineStore({
  id: 'tokenValidation',
  state: (): TokenValidationState => ({
    tokenValid: true,
    apiRequestCount: 0
  }),
  actions: {
    setTokenValid (valid) {
      this.tokenValid = valid
    },
    setApiRequestCount (count: number) {
      this.apiRequestCount = count
    },
    incrementApiRequestCount () {
      this.apiRequestCount++
    }
  },
  getters: {
    getTokenValid(): boolean {
      return this.tokenValid
    },
    getApiRequestCount(): number {
      return this.apiRequestCount
    }
  }
})