import api from '@/utils/api'
import logging from '@/utils/logging'
import { useCacheStore } from '@/stores/cache'

const userUrl = '/verwaltung/benutzereinstellungen/'
const publicUrl = '/verwaltung/globaleansichten/'

export type TypeViewItem = {
  active: boolean
  favorite: boolean
  favoriteMobile: boolean
  name: string
  title: string
  checked?: boolean
}

type ResultItem = {
  id: number
  key: string
  value: TypeViewItem
}

type TypeResult = {
  count: number
  next: null | string
  previous: null | string
  results: ResultItem[]
}

export default {
  async setItem (key: string, value: any) {
    const cacheStore = useCacheStore()
    try {
      const data = { key: key, value: value }
      await api.post(userUrl, data)
      cacheStore.setCache({ url: userUrl, key: key, value: value })
    } catch (error) {
      logging.error(error as string)
      throw error
    }
  },

  async getItem<T = any> (key: string, fromCache = true): Promise<T | null> {
    const cacheStore = useCacheStore()
    try {
      const cache = cacheStore.getCache(userUrl, key)
      if (cache === undefined || fromCache === false) {
        const data: TypeResult = await api.get(`${userUrl}?key=${key}`)
        let value: T | null = null
        if (data.results.length > 0) {
          value = data.results[0].value as T
        }
        cacheStore.setCache({ url: userUrl, key: key, value: value })
        return value
      } else {
        return cache as T
      }
    } catch (error) {
      logging.error(error as string)
      throw error
    }
  },
  /**
   * Öffentliche Ansichten
   */
  async setPublicItem (key: string, gridViewName: string, value: any) {
    try {
      const data = {
        key: key,
        title: gridViewName,
        value: value
      }
      await api.post(publicUrl, data)
    } catch (error) {
      logging.error(error as string)
      throw error
    }
  },
  async getPublicItem (key: string) {
    try {
      const data = await api.get(`${publicUrl}?key=${key}`)
      return data.results
    } catch (error) {
      logging.error(error as string)
      throw error
    }
  },
  deletePublicItem (id: number) {
    return api.delete(`${publicUrl}${id}/`)
  }
}
