import { RouteRecordRaw } from 'vue-router'
import { MODULE_MITARBEITER_COLOR } from '@/modules/moduleConstants'

// Kompetenzen
const KompetenzenForm = () => import('@/modules/kompetenzen/KompetenzenForm.vue')
const KompetenzenList = () => import('@/modules/kompetenzen/KompetenzenList.vue')
const BewertungenList = () => import('@/modules/kompetenzen/BewertungenList.vue')
const BewertungenForm = () => import('@/modules/kompetenzen/BewertungenForm.vue')
const BewertungsskalenForm = () => import('@/modules/kompetenzen/BewertungsskalenForm.vue')
const BewertungsskalenList = () => import('@/modules/kompetenzen/BewertungsskalenList.vue')
const BewertungsskalenwerteForm = () => import('@/modules/kompetenzen/BewertungsskalenwerteForm.vue')
const KompetenzengruppenList = () => import('@/modules/kompetenzen/KompetenzengruppenList.vue')
const KompetenzengruppenForm = () => import('@/modules/kompetenzen/KompetenzengruppenForm.vue')
const KompetenzenMatrix = () => import('@/modules/kompetenzen/KompetenzenMatrix.vue')
const KompetenzenDifferenzenMatrix = () => import('@/modules/kompetenzen/KompetenzenDifferenzenMatrix.vue')


const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const routes: RouteRecordRaw[] = [
  // Kompetenzen
  { path: '/kompetenzen/kompetenzen/',
    name: 'KompetenzenList',
    component: KompetenzenList,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/kompetenzen/kompetenzen/add',
    name: 'KompetenzenForm',
    component: KompetenzenForm,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/kompetenzen/kompetenzen/:id',
    name: 'KompetenzenFormUpdate',
    component: KompetenzenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  //Bewertungen
  { path: '/kompetenzen/bewertungen/',
    name: 'BewertungenList',
    component: BewertungenList,
    meta: { icon: 'mdi-format-list-checkbox', color: MODULE_MITARBEITER_COLOR }
  },
  {
    path: '/kompetenzen/bewertungen/add',
    name: 'BewertungenForm',
    component: BewertungenForm,
    meta: { icon: 'mdi-format-list-checkbox', color: MODULE_MITARBEITER_COLOR }
  },
  {
    path: '/kompetenzen/bewertungen/:id',
    name: 'BewertungenFormUpdate',
    component: BewertungenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-format-list-checkbox', color: MODULE_MITARBEITER_COLOR }
  },
  // Bewertungsskalen
  { path: '/kompetenzen/bewertungsskalen/',
    name: 'BewertungsskalenList',
    component: BewertungsskalenList,
    meta: { icon: 'mdi-ballot-outline', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/kompetenzen/bewertungsskalen/add',
    name: 'BewertungsskalenForm',
    component: BewertungsskalenForm,
    meta: { icon: 'mdi-ballot-outline', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/kompetenzen/bewertungsskalen/:id',
    name: 'BewertungsskalenFormUpdate',
    component: BewertungsskalenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-ballot-outline', color: MODULE_MITARBEITER_COLOR }
  },
  //Bewertungsskalenwerte
  { path: '/kompetenzen/bewertungsskalen/:objectId/bewertungsskalenwerte/add',
    name: 'BewertungsskalenwerteForm',
    component: BewertungsskalenwerteForm,
    props: true,
    meta: { icon: 'mdi-ballot', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/kompetenzen/bewertungsskalenwerte/:id',
    name: 'BewertungsskalenwerteFormUpdate',
    component: BewertungsskalenwerteForm,
    props: updateFormProps,
    meta: { icon: 'mdi-ballot', color: MODULE_MITARBEITER_COLOR }
  },
  //Kompetenzgruppen
  { path: '/kompetenzen/kompetenzengruppen/',
    name: 'KompetenzengruppenList',
    component: KompetenzengruppenList,
    meta: { icon: 'mdi-group', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/kompetenzen/kompetenzengruppen/add',
    name: 'KompetenzengruppenForm',
    component: KompetenzengruppenForm,
    meta: { icon: 'mdi-group', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/kompetenzen/kompetenzengruppen/:id',
    name: 'KompetenzengruppenFormUpdate',
    component: KompetenzengruppenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-group', color: MODULE_MITARBEITER_COLOR }
  },
  //Kompetenzenmatrix
  { path: '/kompetenzen/kompetenzenmatrix/',
    name: 'KompetenzenMatrix',
    component: KompetenzenMatrix,
    meta: { icon: 'mdi-format-list-checkbox', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/kompetenzen/kompetenzendifferenzenmatrix/',
    name: 'KompetenzenDifferenzenMatrix',
    component: KompetenzenDifferenzenMatrix,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  }
]

export default routes
