import { RouteRecordRaw } from 'vue-router'
import { MODULE_PRAEVENTION_COLOR } from '@/modules/moduleConstants'

// Praevention
const GefaehrdungenList = () => import('@/modules/praevention/GefaehrdungenList.vue')
const GefaehrdungenForm = () => import('@/modules/praevention/GefaehrdungenForm.vue')
const GruppenPraeventionList = () => import('@/modules/praevention/GruppenPraeventionList.vue')
const GruppenPraeventionForm = () => import('@/modules/praevention/GruppenPraeventionForm.vue')
const KategorienPraeventionList = () => import('@/modules/praevention/KategorienPraeventionList.vue')
const KategorienPraeventionForm = () => import('@/modules/praevention/KategorienPraeventionForm.vue')
const GefahrstoffeForm = () => import('@/modules/praevention/GefahrstoffeForm.vue')
const GefahrstoffeList = () => import('@/modules/praevention/GefahrstoffeList.vue')
const HerstellerPraeventionForm = () => import('@/modules/praevention/HerstellerPraeventionForm.vue')
const HerstellerPraeventionList = () => import('@/modules/praevention/HerstellerPraeventionList.vue')
const VerwendungszweckList = () => import('@/modules/praevention/VerwendungszweckList.vue')
const VerwendungszweckForm = () => import('@/modules/praevention/VerwendungszweckForm.vue')
const BerechnungForm = () => import('@/modules/praevention/BerechnungForm.vue')
const AnalysenList = () => import('@/modules/praevention/AnalysenList.vue')
const AnalysenMap = () => import('@/modules/praevention/AnalysenMap.vue')
const AnalysenForm = () => import('@/modules/praevention/AnalysenForm.vue')
const AbfragelistenList = () => import('@/modules/praevention/AbfragelistenList.vue')
const AbfragelistenForm = () => import('@/modules/praevention/AbfragelistenForm.vue')
const AbfragegruppenList = () => import('@/modules/praevention/AbfragegruppenList.vue')
const AbfragegruppenForm = () => import('@/modules/praevention/AbfragegruppenForm.vue')
const AbfrageForm = () => import('@/modules/praevention/AbfrageForm.vue')
const FragenList = () => import('@/modules/praevention/FragenList.vue')
const FragenForm = () => import('@/modules/praevention/FragenForm.vue')
const BewertungsmethodeList = () => import('@/modules/praevention/BewertungsmethodeList.vue')
const BewertungsmethodeForm = () => import('@/modules/praevention/BewertungsmethodeForm.vue')
const BewertungForm = () => import('@/modules/praevention/BewertungForm.vue')
const BewertungsmatrixList = () => import('@/modules/praevention/BewertungsmatrixList.vue')
const BewertungsmatrixForm = () => import('@/modules/praevention/BewertungsmatrixForm.vue')
const EinflussfaktorForm = () => import('@/modules/praevention/EinflussfaktorForm.vue')
const EfwerteForm = () => import('@/modules/praevention/EfwerteForm.vue')
const AnalysenMatrix = () => import('@/modules/praevention/AnalysenMatrix.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const updateFormObjectProps = (route) => {
  return {
    modul: route.params.modul,
    model: route.params.model,
    objectId: parseInt(route.params.objectId)
  }
}

const routes: RouteRecordRaw[] = [
  // Gefaehrdung
  { path: '/praevention/gefaehrdung/',
    name: 'GefaehrdungenList',
    component: GefaehrdungenList,
    meta: { icon: 'mdi-hazard-lights', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/gefaehrdung/add',
    name: 'GefaehrdungenForm',
    component: GefaehrdungenForm,
    meta: { icon: 'mdi-hazard-lights', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/gefaehrdung/:id',
    name: 'GefaehrdungenFormUpdate',
    component: GefaehrdungenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-hazard-lights', color: MODULE_PRAEVENTION_COLOR }
  },
  // Gruppen (Praevention)
  { path: '/praevention/gruppen/',
    name: 'GruppenPraeventionList',
    component: GruppenPraeventionList,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/gruppen/add',
    name: 'GruppenPraeventionForm',
    component: GruppenPraeventionForm,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/gruppen/:id',
    name: 'GruppenPraeventionFormUpdate',
    component: GruppenPraeventionForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  // Kategorien (Praevention)
  { path: '/praevention/kategorien/',
    name: 'KategorienPraeventionList',
    component: KategorienPraeventionList,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/kategorien/add',
    name: 'KategorienPraeventionForm',
    component: KategorienPraeventionForm,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/kategorien/:id',
    name: 'KategorienPraeventionFormUpdate',
    component: KategorienPraeventionForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  // Gefahrstoffe
  { path: '/praevention/gefahrstoffe/',
    name: 'GefahrstoffeList',
    component: GefahrstoffeList,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/gefahrstoffe/add',
    name: 'GefahrstoffeForm',
    component: GefahrstoffeForm,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/gefahrstoffe/:id',
    name: 'GefahrstoffeFormUpdate',
    component: GefahrstoffeForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  // Hersteller (Praevention)
  { path: '/praevention/hersteller/',
    name: 'HerstellerPraeventionList',
    component: HerstellerPraeventionList,
    meta: { icon: 'mdi-factory', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/hersteller/add',
    name: 'HerstellerPraeventionForm',
    component: HerstellerPraeventionForm,
    meta: { icon: 'mdi-factory', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/hersteller/:id',
    name: 'HerstellerPraeventionFormUpdate',
    component: HerstellerPraeventionForm,
    props: updateFormProps,
    meta: { icon: 'mdi-factory', color: MODULE_PRAEVENTION_COLOR }
  },
  // Verwendungszweck (Praevention)
  { path: '/praevention/verwendungszwecke/',
    name: 'VerwendungszewckeList',
    component: VerwendungszweckList,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/verwendungszwecke/add',
    name: 'VerwendungszweckForm',
    component: VerwendungszweckForm,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/verwendungszwecke/:id',
    name: 'VerwendungszweckFormUpdate',
    component: VerwendungszweckForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  // Analysen
  { path: '/praevention/analysen/',
    name: 'AnalysenList',
    component: AnalysenList,
    meta: { icon: 'mdi-trending-up', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/analysen/map',
    name: 'AnalysenMap',
    component: AnalysenMap,
    meta: { icon: 'mdi-trending-up', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/analysen/add',
    name: 'AnalysenForm',
    component: AnalysenForm,
    meta: { icon: 'mdi-trending-up', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/analysen/:id',
    name: 'AnalysenFormUpdate',
    component: AnalysenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-trending-up', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/:modul/:model/:objectId/fragen/',
    name: 'AnalysenObjektFragenList',
    component: FragenList,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-clipboard-text', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/:modul/:model/:objectId/analysen/add',
    name: 'AnalysenObjektForm',
    component: AnalysenForm,
    props: true,
    meta: { icon: 'mdi-trending-up', color: MODULE_PRAEVENTION_COLOR }
  },
  // Analysen - Frage
  { path: '/praevention/fragen/add',
    name: 'FragenForm',
    component: FragenForm,
    meta: { icon: 'mdi-clipboard-text', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/fragen/:id',
    name: 'FragenFormUpdate',
    component: FragenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-clipboard-text', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/:modul/:model/:objectId/fragen/add',
    name: 'FragenObjektForm',
    component: FragenForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-clipboard-text', color: MODULE_PRAEVENTION_COLOR }
  },
  // Analysen - Bewertungsmethode
  { path: '/praevention/bewertungsmethode/',
    name: 'BewertungsmethodeList',
    component: BewertungsmethodeList,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/bewertungsmethode/add',
    name: 'BewertungsmethodeForm',
    component: BewertungsmethodeForm,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/bewertungsmethode/:id',
    name: 'BewertungsmethodeFormUpdate',
    component: BewertungsmethodeForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  // Analysen - Bewertung
  { path: '/praevention/bewertungsmethode/:objectId/bewertungen/add',
    name: 'BewertungForm',
    component: BewertungForm,
    props: true,
    meta: { icon: 'mdi-chart-box', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/bewertung/:id',
    name: 'BewertungFormUpdate',
    component: BewertungForm,
    props: updateFormProps,
    meta: { icon: 'mdi-chart-box', color: MODULE_PRAEVENTION_COLOR }
  },
  // Analysen - Bewertungsmatrix
  { path: '/praevention/bewertungsmatrix/',
    name: 'BewertungsmatrixList',
    component: BewertungsmatrixList,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/bewertungsmatrix/add',
    name: 'BewertungsmatrixForm',
    component: BewertungsmatrixForm,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/bewertungsmatrix/:id',
    name: 'BewertungsmatrixFormUpdate',
    component: BewertungsmatrixForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  //Analysenmatrix
  { path: '/praevention/analysenmatrix/',
    name: 'AnalysenMatrix',
    component: AnalysenMatrix,
    meta: { icon: 'mdi-format-list-checkbox', color: MODULE_PRAEVENTION_COLOR }
  },
  // Analysen - Berechnungen
  { path: '/praevention/bewertungsmatrix/:objectId/berechnungen/add',
    name: 'BerechnungForm',
    component: BerechnungForm,
    props: true,
    meta: { icon: 'mdi-chart-box', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/berechnung/:id',
    name: 'BerechnungFormUpdate',
    component: BerechnungForm,
    props: updateFormProps,
    meta: { icon: 'mdi-chart-box', color: MODULE_PRAEVENTION_COLOR }
  },
  // Analysen - Einflussfaktor
  { path: '/praevention/bewertungsmatrix/:objectId/einflussfaktoren/add',
    name: 'EinflussfaktorForm',
    component: EinflussfaktorForm,
    props: true,
    meta: { icon: 'mdi-car-cruise-control', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/einflussfaktor/:id',
    name: 'EinflussfaktorFormUpdate',
    component: EinflussfaktorForm,
    props: updateFormProps,
    meta: { icon: 'mdi-car-cruise-control', color: MODULE_PRAEVENTION_COLOR }
  },
  // Analysen - EinflussfaktorWerte
  { path: '/praevention/einflussfaktor/:objectId/efwerte/add',
    name: 'EfwerteForm',
    component: EfwerteForm,
    props: true,
    meta: { icon: 'mdi-numeric', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/efwerte/:id',
    name: 'EfwerteFormUpdate',
    component: EfwerteForm,
    props: updateFormProps,
    meta: { icon: 'mdi-numeric', color: MODULE_PRAEVENTION_COLOR }
  },
  // Abfragelisten
  { path: '/praevention/abfrageliste/',
    name: 'AbfragelistenList',
    component: AbfragelistenList,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/abfrageliste/add',
    name: 'AbfragelistenForm',
    component: AbfragelistenForm,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/abfrageliste/:id',
    name: 'AbfragelistenFormUpdate',
    component: AbfragelistenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  // Abfragegruppen
  { path: '/praevention/abfragegruppe/',
    name: 'AbfragegruppenList',
    component: AbfragegruppenList,
    meta: { icon: 'mdi-group', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/abfragegruppe/:id',
    name: 'AbfragegruppenFormUpdate',
    component: AbfragegruppenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-group', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/abfrageliste/:objectId/abfragegruppen/add',
    name: 'AbfragegruppenForm',
    component: AbfragegruppenForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-group', color: MODULE_PRAEVENTION_COLOR }
  },
  // Abfragen
  { path: '/praevention/abfrage/:id',
    name: 'AbfrageFormUpdate',
    component: AbfrageForm,
    props: updateFormProps,
    meta: { icon: 'mdi-format-list-checkbox', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/abfrageliste/:objectId/abfragen/add',
    name: 'AbfrageForm',
    component: AbfrageForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-format-list-checkbox', color: MODULE_PRAEVENTION_COLOR }
  }
]

export default routes
