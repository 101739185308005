import { RouteRecordRaw } from 'vue-router'
import { MODULE_MANAGEMENT_COLOR } from '@/modules/moduleConstants'

// Management
const ProzesseList = () => import('@/modules/management/ProzesseList.vue')
const ProzessVorlagenList = () => import('@/modules/management/ProzessVorlagenList.vue')
const ProzessVorlagenForm = () => import('@/modules/management/ProzessVorlagenForm.vue')
// const ProzessSchritteList = () => import('@/modules/management/ProzessSchritteList.vue')
const ProzessSchritteForm = () => import('@/modules/management/ProzessSchritteForm.vue')
const RechtsanforderungenVorlagenList = () => import('@/modules/management/RechtsanforderungenVorlagenList.vue')
const RechtsanforderungenVorlagenForm = () => import('@/modules/management/RechtsanforderungenVorlagenForm.vue')
const RechtsanforderungenVorlagenGruppenForm = () => import('@/modules/management/RechtsanforderungenVorlagenGruppenForm.vue')
const RechtsanforderungenVorlagenRechtsanforderungenForm = () => import('@/modules/management/RechtsanforderungenVorlagenRechtsanforderungenForm.vue')
const RechtsanforderungenListenList = () => import('@/modules/management/RechtsanforderungenListenList.vue')
const RechtsanforderungenListenForm = () => import('@/modules/management/RechtsanforderungenListenForm.vue')
const RechtsanforderungenListenDarlegungenForm = () => import('@/modules/management/RechtsanforderungenListenDarlegungenForm.vue')
const RechtsanforderungenListenBerichteForm = () => import('@/modules/management/RechtsanforderungenListenBerichteForm.vue')
const RKForm = () => import('@/modules/management/RKForm.vue')
const RKList = () => import('@/modules/management/RKList.vue')
const DarlegungList = () => import('@/modules/management/DarlegungList.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const updateFormObjectProps = (route) => {
  return {
    modul: route.params.modul,
    model: route.params.model,
    objectId: parseInt(route.params.objectId)
  }
}

const routes: RouteRecordRaw[] = [
  // Prozesse
  { path: '/management/prozesse/',
    name: 'ProzesseList',
    component: ProzesseList,
    meta: { icon: 'mdi-ballot-outline', color: MODULE_MANAGEMENT_COLOR }
  },
  // Prozessvorlagen
  { path: '/management/prozessvorlagen/',
    name: 'ProzessVorlagenList',
    component: ProzessVorlagenList,
    meta: { icon: '', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/management/prozessvorlagen/add',
    name: 'ProzessVorlagenForm',
    component: ProzessVorlagenForm,
    meta: { icon: '', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/management/prozessvorlagen/:id',
    name: 'ProzessVorlagenFormUpdate',
    component: ProzessVorlagenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_MANAGEMENT_COLOR }
  },
  // Prozessschritte
  // { path: '/management/prozessschritte/',
  //   name: 'ProzessSchritteList',
  //   component: ProzessSchritteList },
  // { path: '/management/prozessschritte/add',
  //   name: 'ProzessSchritteForm',
  //   component: ProzessSchritteForm },
  { path: '/management/prozessschritte/:id',
    name: 'ProzessSchritteFormUpdate',
    component: ProzessSchritteForm,
    props: updateFormProps,
    meta: { icon: 'mdi-file-tree', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/:modul/:model/:objectId/prozessschritte/add',
    name: 'ProzessSchritteObjektForm',
    component: ProzessSchritteForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-file-tree', color: MODULE_MANAGEMENT_COLOR }
  },
  // Rechtsanforderungen
  { path: '/management/rechtsanforderungenvorlagen/',
    name: 'RechtsanforderungenVorlagenList',
    component: RechtsanforderungenVorlagenList,
    meta: { icon: '', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/management/rechtsanforderungenvorlagen/add',
    name: 'RechtsanforderungenVorlagenForm',
    component: RechtsanforderungenVorlagenForm,
    meta: { icon: '', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/management/rechtsanforderungenvorlagen/:id',
    name: 'RechtsanforderungenVorlagenFormUpdate',
    component: RechtsanforderungenVorlagenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/management/rkgruppe/add',
    name: 'RechtsanforderungenVorlagenGruppenForm',
    component: RechtsanforderungenVorlagenGruppenForm,
    meta: { icon: 'mdi-file-tree', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/management/rkgruppe/:id',
    name: 'RechtsanforderungenVorlagenGruppenFormUpdate',
    component: RechtsanforderungenVorlagenGruppenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-file-tree', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/:modul/:model/:objectId/rkgruppe/add',
    name: 'RechtsanforderungenVorlagenGruppenObjektForm',
    component: RechtsanforderungenVorlagenGruppenForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-file-tree', color: MODULE_MANAGEMENT_COLOR }
  },
//
  { path: '/management/rechtsanforderungenvorlagenrechtsanforderungen/add',
    name: 'RechtsanforderungenVorlagenRechtsanforderungenForm',
    component: RechtsanforderungenVorlagenRechtsanforderungenForm,
    meta: { icon: 'mdi-format-section', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/management/rechtsanforderungenvorlagenrechtsanforderungen/:id',
    name: 'RechtsanforderungenVorlagenRechtsanforderungenFormUpdate',
    component: RechtsanforderungenVorlagenRechtsanforderungenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-format-section', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/:modul/:model/:objectId/rechtsanforderungenvorlagenrechtsanforderungen/add',
    name: 'RechtsanforderungenVorlagenRechtsanforderungenObjektForm',
    component: RechtsanforderungenVorlagenRechtsanforderungenForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-format-section', color: MODULE_MANAGEMENT_COLOR }
  },
//
  { path: '/management/rechtsanforderungenlisten/',
    name: 'RechtsanforderungenListenList',
    component: RechtsanforderungenListenList,
    meta: { icon: '', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/management/rechtsanforderungenlisten/add',
    name: 'RechtsanforderungenListenForm',
    component: RechtsanforderungenListenForm,
    meta: { icon: '', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/management/rechtsanforderungenlisten/:id',
    name: 'RechtsanforderungenListenFormUpdate',
    component: RechtsanforderungenListenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/:modul/:model/:objectId/darlegungen/',
    name: 'RKlisteObjektDralegungenList',
    component: DarlegungList,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-clipboard-text', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/:modul/:model/:objectId/darlegungen/add',
    name: 'DarlegungObjektForm',
    component: RechtsanforderungenListenDarlegungenForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-file-tree', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/management/rkdarlegung/:id',
    name: 'RechtsanforderungenListenDarlegungenFormUpdate',
    component: RechtsanforderungenListenDarlegungenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-file-tree', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/:modul/:model/:objectId/rkdarlegung/add',
    name: 'RechtsanforderungenListenDarlegungenObjektForm',
    component: RechtsanforderungenListenDarlegungenForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-file-tree', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/management/rkbericht/:id',
    name: 'RechtsanforderungenListenBerichteFormUpdate',
    component: RechtsanforderungenListenBerichteForm,
    props: updateFormProps,
    meta: { icon: 'mdi-file-tree', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/:modul/:model/:objectId/rkbericht/add',
    name: 'RechtsanforderungenListenBerichteObjektForm',
    component: RechtsanforderungenListenBerichteForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-file-tree', color: MODULE_MANAGEMENT_COLOR }
  },
  // RKListe
  { path: '/management/rkliste/',
    name: 'RKList',
    component: RKList,
    meta: { icon: '', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/management/rkliste/add',
    name: 'RKForm',
    component: RKForm,
    meta: { icon: '', color: MODULE_MANAGEMENT_COLOR }
  },
  { path: '/management/rkliste/:id',
    name: 'RKFormUpdate',
    component: RKForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_MANAGEMENT_COLOR }
  },
]

export default routes
