import { RouteRecordRaw } from 'vue-router'
import { MODULE_AUFGABEN_COLOR } from '@/modules/moduleConstants'

// Labels
const LabelsForm = () => import('@/modules/labels/LabelsForm.vue')
const LabelsList = () => import('@/modules/labels/LabelsList.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const routes: RouteRecordRaw[] = [
  // Labels
  { path: '/labels/labels/',
    name: 'LabelsList',
    component: LabelsList,
    meta: { icon: 'mdi-label-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/labels/labels/add',
    name: 'LabelsForm',
    component: LabelsForm,
    meta: { icon: 'mdi-label-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/labels/labels/:id',
    name: 'LabelsFormUpdate',
    component: LabelsForm,
    props: updateFormProps,
    meta: { icon: 'mdi-label-outline', color: MODULE_AUFGABEN_COLOR }
  },
]

export default routes
