import { RouteRecordRaw } from 'vue-router'
import { MODULE_AUFGABEN_COLOR } from '@/modules/moduleConstants'

const EmailQueueList = () => import('@/modules/emailing/EmailQueueList.vue')
const EmailQueueForm = () => import('@/modules/emailing/EmailQueueForm.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const routes: RouteRecordRaw[] = [
  { path: '/emailing/emailqueue/',
    name: 'EmailQueueList',
    component: EmailQueueList,
    meta: { icon: '', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/emailing/emailqueue/:id',
    name: 'EmailQueueUpdate',
    component: EmailQueueForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_AUFGABEN_COLOR }
  },
]

export default routes
