import { LocaleMessages, createI18n } from 'vue-i18n'
import de from '@/translations/de.json'
import it from '@/translations/it.json'
import en from '@/translations/en.json'
import { UILanguage } from '@/types'

/** Type mit allen vorhandenen Translation Strings. Mit Vue-18n */
export type MessageSchema = typeof de
export type TranslationKey = keyof MessageSchema | ''

/** Lädt die Translations und entfernt leere Einträge */
function loadLocaleMessages (): LocaleMessages<MessageSchema, UILanguage> {
  const messages: Record<UILanguage, MessageSchema> = {
    'de': Object.assign({}, de),
    'it': Object.assign({}, it),
    'en': Object.assign({}, en)
  }
  for (const locale of ['de', 'it', 'en']) {
    for (const key of Object.keys(messages[locale])) {
      if (!messages[locale][key]) {
        console.warn(`Fehlende Übersetzung für "${key}" in Locale ${locale}`)
        delete messages[locale][key]
      }
    }
  }
  return messages
}

export default createI18n<[MessageSchema], UILanguage>({
  legacy: false,
  globalInjection: false,
  locale: import.meta.env.VITE_APP_I18N_LOCALE || 'de',
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages: loadLocaleMessages()
})