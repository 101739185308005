import { RouteRecordRaw } from 'vue-router'
import { MODULE_MITARBEITER_COLOR } from '@/modules/moduleConstants'

// Mitarbeiter
const MitarbeiterGruppenList = () => import('@/modules/mitarbeiter/MitarbeiterGruppenList.vue')
const MitarbeiterGruppenForm = () => import('@/modules/mitarbeiter/MitarbeiterGruppenForm.vue')
const MitarbeiterList = () => import('@/modules/mitarbeiter/MitarbeiterList.vue')
const MitarbeiterMap = () => import('@/modules/mitarbeiter/MitarbeiterMap.vue')
const MitarbeiterForm = () => import('@/modules/mitarbeiter/MitarbeiterForm.vue')
const BeschaeftigungszeitraumForm = () => import('@/modules/mitarbeiter/BeschaeftigungszeitraumForm.vue')
const GruppeTaetigkeitList = () => import('@/modules/mitarbeiter/GruppeTaetigkeitList.vue')
const GruppeTaetigkeitForm = () => import('@/modules/mitarbeiter/GruppeTaetigkeitForm.vue')
const TaetigkeitenList = () => import('@/modules/mitarbeiter/TaetigkeitenList.vue')
const TaetigkeitenForm = () => import('@/modules/mitarbeiter/TaetigkeitenForm.vue')
const TaetigkeitenMatrix = () => import('@/modules/mitarbeiter/TaetigkeitenMatrix.vue')
const KursKategorienList = () => import('@/modules/mitarbeiter/KursKategorienList.vue')
const KursKategorienForm = () => import('@/modules/mitarbeiter/KursKategorienForm.vue')
const KurseList = () => import('@/modules/mitarbeiter/KurseList.vue')
const KurseForm = () => import('@/modules/mitarbeiter/KurseForm.vue')
const AusbildungsstundenForm = () => import('@/modules/mitarbeiter/AusbildungsstundenForm.vue')
const AusbildungsnachweiseForm = () => import('@/modules/mitarbeiter/AusbildungsnachweiseForm.vue')
const AusbildungList = () => import('@/modules/mitarbeiter/AusbildungList.vue')
const AusbildungForm = () => import('@/modules/mitarbeiter/AusbildungForm.vue')
const SchulungsplanungList = () => import('@/modules/mitarbeiter/SchulungsplanungList.vue')
const SchutzausruestungenList = () => import('@/modules/mitarbeiter/SchutzausruestungenList.vue')
const SchutzausruestungenForm = () => import('@/modules/mitarbeiter/SchutzausruestungenForm.vue')
const QualifikationKategorieList = () => import('@/modules/mitarbeiter/QualifikationKategorieList.vue')
const QualifikationKategorieForm = () => import('@/modules/mitarbeiter/QualifikationKategorieForm.vue')
const QualifikationList = () => import('@/modules/mitarbeiter/QualifikationList.vue')
const QualifikationForm = () => import('@/modules/mitarbeiter/QualifikationForm.vue')
const QualifikationMatrix = () => import('@/modules/mitarbeiter/QualifikationMatrix.vue')
const VisitenTypenForm = () => import('@/modules/mitarbeiter/VisitenTypenForm.vue')
const VisitenTypenList = () => import('@/modules/mitarbeiter/VisitenTypenList.vue')
const VisitenForm = () => import('@/modules/mitarbeiter/VisitenForm.vue')
const VisitenList = () => import('@/modules/mitarbeiter/VisitenList.vue')
const VisitenTerminList = () => import('@/modules/mitarbeiter/VisitenTerminList.vue')
const VisitenTerminForm = () => import('@/modules/mitarbeiter/VisitenTerminForm.vue')
const AusbildungsstundenExportList = () => import('@/modules/mitarbeiter/AusbildungsstundenExportList.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const updateDetailFormProps = (route) => {
  return {
    objectId: parseInt(route.params.objectId),
    id: parseInt(route.params.id)
  }
}

const updateFormObjectProps = (route) => {
  return {
    modul: route.params.modul,
    model: route.params.model,
    objectId: parseInt(route.params.objectId)
  }
}

const routes: RouteRecordRaw[] = [
  // MitarbeiterGruppe
  { path: '/personen/gruppen/',
    name: 'MitarbeiterGruppenList',
    component: MitarbeiterGruppenList,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/gruppen/add',
    name: 'MitarbeiterGruppenForm',
    component: MitarbeiterGruppenForm,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/gruppen/:id',
    name: 'MitarbeiterGruppenFormUpdate',
    component: MitarbeiterGruppenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_MITARBEITER_COLOR }
  },
  // Mitarbeiter
  { path: '/personen/personen/',
    name: 'MitarbeiterList',
    component: MitarbeiterList,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/personen/map',
    name: 'MitarbeiterMap',
    component: MitarbeiterMap,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/personen/add',
    name: 'MitarbeiterForm',
    component: MitarbeiterForm,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/personen/:id',
    name: 'MitarbeiterFormUpdate',
    component: MitarbeiterForm,
    props: updateFormProps,
    meta: { icon: 'mdi-account-multiple-outline', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/beschaeftigungszeitraume/add',
    name: 'BeschaeftigungszeitraumForm',
    component: BeschaeftigungszeitraumForm,
    meta: { icon: 'mdi-calendar-range', color: MODULE_MITARBEITER_COLOR }
  },
  // { path: '/personen/beschaeftigungszeitraume/:id',
  //   name: 'BeschaeftigungszeitraumFormUpdate',
  //   component: BeschaeftigungszeitraumForm,
  //   props: updateFormProps },
  { path: '/:modul/:model/:objectId/beschaeftigungszeitraume/add',
    name: 'BeschaeftigungszeitraumObjektForm',
    component: BeschaeftigungszeitraumForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-calendar-range', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/:modul/:model/:objectId/beschaeftigungszeitraume/:id',
    name: 'BeschaeftigungszeitraumFormUpdate',
    component: BeschaeftigungszeitraumForm,
    props: updateDetailFormProps,
    meta: { icon: 'mdi-calendar-range', color: MODULE_MITARBEITER_COLOR }
  },
  // GruppeTaetigkeit
  { path: '/personen/gruppetaetigkeiten/',
    name: 'GruppeTaetigkeitList',
    component: GruppeTaetigkeitList,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/gruppetaetigkeiten/add',
    name: 'GruppeTaetigkeitForm',
    component: GruppeTaetigkeitForm,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/gruppetaetigkeiten/:id',
    name: 'GruppeTaetigkeitFormUpdate',
    component: GruppeTaetigkeitForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  // Taetigkeiten
  { path: '/personen/berufsbezeichnungen/',
    name: 'TaetigkeitenList',
    component: TaetigkeitenList,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/berufsbezeichnungen/add',
    name: 'TaetigkeitenForm',
    component: TaetigkeitenForm,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/berufsbezeichnungen/:id',
    name: 'TaetigkeitenFormUpdate',
    component: TaetigkeitenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/taetigkeitsmatrix/',
    name: 'TaetigkeitenMatrix',
    component: TaetigkeitenMatrix,
    meta: { icon: 'mdi-format-list-checkbox', color: MODULE_MITARBEITER_COLOR }
  },
  // KursKategorien
  { path: '/personen/kurskategorien/',
    name: 'KursKategorienList',
    component: KursKategorienList,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/kurskategorien/add',
    name: 'KursKategorienForm',
    component: KursKategorienForm,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/kurskategorien/:id',
    name: 'KursKategorienFormUpdate',
    component: KursKategorienForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  // Kurse
  { path: '/personen/kurse/',
    name: 'KurseList',
    component: KurseList,
    meta: { icon: 'mdi-school-outline', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/kurse/add',
    name: 'KurseForm',
    component: KurseForm,
    meta: { icon: 'mdi-school-outline', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/kurse/:id',
    name: 'KurseFormUpdate',
    component: KurseForm,
    props: updateFormProps,
    meta: { icon: 'mdi-school-outline', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/ausbildungsstunden/add',
    name: 'AusbildungsstundenForm',
    component: AusbildungsstundenForm,
    meta: { icon: 'mdi-calendar-range', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/ausbildungsstunden/:id',
    name: 'AusbildungsstundenFormUpdate',
    component: AusbildungsstundenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-calendar-range', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/:modul/:model/:objectId/ausbildungsstunden/add',
    name: 'AusbildungsstundenObjektForm',
    component: AusbildungsstundenForm,
    props: true,
    meta: { icon: 'mdi-calendar-range', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/ausbildungsnachweise/add',
    name: 'AusbildungsnachweiseForm',
    component: AusbildungsnachweiseForm,
    meta: { icon: 'mdi-certificate-outline', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/ausbildungsnachweise/:id',
    name: 'AusbildungsnachweiseFormUpdate',
    component: AusbildungsnachweiseForm,
    props: updateFormProps,
    meta: { icon: 'mdi-certificate-outline', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/:modul/:model/:objectId/ausbildungsnachweise/add',
    name: 'AusbildungsnachweiseObjektForm',
    component: AusbildungsnachweiseForm,
    props: true,
    meta: { icon: 'mdi-certificate-outline', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/:modul/:model/:objectId/kurse/add',
    name: 'KurseObjektForm',
    component: KurseForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-school-outline', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/ausbildungsstundenexport/',
    name: 'AusbildungsstundenExportList',
    component: AusbildungsstundenExportList,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  // Schulungsplanung
  { path: '/personen/schulungsplanung/',
    name: 'SchulungsplanungList',
    component: SchulungsplanungList,
    meta: { icon: 'mdi-school-outline', color: MODULE_MITARBEITER_COLOR }
  },
  // Schutzausruestungen
  { path: '/personen/psa/',
    name: 'SchutzausruestungenList',
    component: SchutzausruestungenList,
    meta: { icon: 'mdi-safety-goggles', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/psa/add',
    name: 'SchutzausruestungenForm',
    component: SchutzausruestungenForm,
    meta: { icon: 'mdi-safety-goggles', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/psa/:id',
    name: 'SchutzausruestungenFormUpdate',
    component: SchutzausruestungenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-safety-goggles', color: MODULE_MITARBEITER_COLOR }
  },
  // QualifikationKategorie
  { path: '/personen/qualifikationkategorien/',
    name: 'QualifikationKategorieList',
    component: QualifikationKategorieList,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/qualifikationkategorien/add',
    name: 'QualifikationKategorieForm',
    component: QualifikationKategorieForm,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/qualifikationkategorien/:id',
    name: 'QualifikationKategorieFormUpdate',
    component: QualifikationKategorieForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  // Qualifikation
  { path: '/personen/qualifikationen/',
    name: 'QualifikationList',
    component: QualifikationList,
    meta: { icon: 'mdi-account-check', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/qualifikationen/add',
    name: 'QualifikationForm',
    component: QualifikationForm,
    meta: { icon: 'mdi-account-check', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/qualifikationen/:id',
    name: 'QualifikationFormUpdate',
    component: QualifikationForm,
    props: updateFormProps,
    meta: { icon: 'mdi-account-check', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/qualifikationsmatrix/',
    name: 'QualifikationsMatrix',
    component: QualifikationMatrix,
    meta: { icon: 'mdi-format-list-checkbox', color: MODULE_MITARBEITER_COLOR }
  },
  // Ausbildung
  { path: '/personen/ausbildungen/',
    name: 'AusbildungList',
    component: AusbildungList,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/ausbildungen/add',
    name: 'AusbildungForm',
    component: AusbildungForm,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/ausbildungen/:id',
    name: 'AusbildungFormUpdate',
    component: AusbildungForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  // Visitentypen
  { path: '/personen/visitentypen/',
    name: 'VisitenTypenList',
    component: VisitenTypenList,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/visitentypen/add',
    name: 'VisitenTypenForm',
    component: VisitenTypenForm,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/visitentypen/:id',
    name: 'VisitenTypenFormUpdate',
    component: VisitenTypenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_MITARBEITER_COLOR }
  },
  // Visiten
  { path: '/personen/visiten/',
    name: 'VisitenList',
    component: VisitenList,
    props: updateFormProps,
    meta: { icon: 'mdi-hospital', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/visiten/add',
    name: 'VisitenForm',
    component: VisitenForm,
    meta: { icon: 'mdi-hospital', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/visiten/:id',
    name: 'VisitenFormUpdate',
    component: VisitenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-hospital', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/:modul/:model/:objectId/visiten/add',
    name: 'VisitenObjektForm',
    component: VisitenForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-hospital', color: MODULE_MITARBEITER_COLOR }
  },
  // Geplante Visiten
  { path: '/personen/visitentermin/',
    name: 'VisitenTerminList',
    component: VisitenTerminList,
    meta: { icon: 'mdi-hospital', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/visitentermin/add',
    name: 'VisitenTerminForm',
    component: VisitenTerminForm,
    meta: { icon: 'mdi-hospital', color: MODULE_MITARBEITER_COLOR }
  },
  { path: '/personen/visitentermin/:id',
    name: 'VisitenTerminFormUpdate',
    component: VisitenTerminForm,
    props: updateFormProps,
    meta: { icon: 'mdi-hospital', color: MODULE_MITARBEITER_COLOR }
  },
]

export default routes
