import { RouteRecordRaw } from 'vue-router'
import { MODULE_AUFGABEN_COLOR } from '@/modules/moduleConstants'

// Aufgaben
const AufgabenList = () => import('@/modules/aufgaben/AufgabenList.vue')
const AufgabenMap = () => import('@/modules/aufgaben/AufgabenMap.vue')
const AufgabenForm = () => import('@/modules/aufgaben/AufgabenForm.vue')
const AufgabenPeriodizitaetenList = () => import('@/modules/aufgaben/AufgabenPeriodizitaetenList.vue')
const AufgabenPeriodizitaetenForm = () => import('@/modules/aufgaben/AufgabenPeriodizitaetenForm.vue')
const AufgabenVorlagenList = () => import('@/modules/aufgaben/AufgabenVorlagenList.vue')
const AufgabenVorlagenForm = () => import('@/modules/aufgaben/AufgabenVorlagenForm.vue')
const AufgabenFeiertageList = () => import('@/modules/aufgaben/AufgabenFeiertageList.vue')
const AufgabenFeiertageForm = () => import('@/modules/aufgaben/AufgabenFeiertageForm.vue')
const ChecklistenList = () => import('@/modules/aufgaben/ChecklistenList.vue')
const ChecklistenForm = () => import('@/modules/aufgaben/ChecklistenForm.vue')
const CheckpunkteList = () => import('@/modules/aufgaben/CheckpunkteList.vue')
const CheckpunkteForm = () => import('@/modules/aufgaben/CheckpunkteForm.vue')
const CheckpunktWerteForm = () => import('@/modules/aufgaben/CheckpunktWerteForm.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const updateFormObjectProps = (route) => {
  return {
    modul: route.params.modul,
    model: route.params.model,
    objectId: parseInt(route.params.objectId)
  }
}

const routes: RouteRecordRaw[] = [
  // Aufgaben
  { path: '/aufgaben/aufgaben/',
    name: 'AufgabenList',
    component: AufgabenList,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/aufgaben/map',
    name: 'AufgabenMap',
    component: AufgabenMap,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/aufgaben/add',
    name: 'AufgabenForm',
    component: AufgabenForm,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/aufgaben/:id',
    name: 'AufgabenFormUpdate',
    component: AufgabenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  // Aufgaben AddForObject
  { path: '/:modul/:model/:objectId/aufgaben/add',
    name: 'AufgabenObjektForm',
    component: AufgabenForm,
    props: true,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  // Aufgaben Mobile ListForObject
  { path: '/:modul/:model/:objectId/aufgaben/',
    name: 'AufgabenObjektList',
    component: AufgabenList,
    props: true,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  // AufgabenPeriodizitaeten
  { path: '/aufgaben/aufgabenperiodizitaeten/',
    name: 'AufgabenPeriodizitaetenList',
    component: AufgabenPeriodizitaetenList,
    meta: { icon: 'mdi-calendar-sync', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/aufgabenperiodizitaeten/add',
    name: 'AufgabenPeriodizitaetenForm',
    component: AufgabenPeriodizitaetenForm,
    meta: { icon: 'mdi-calendar-sync', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/aufgabenperiodizitaeten/:id',
    name: 'AufgabenPeriodizitaetenFormUpdate',
    component: AufgabenPeriodizitaetenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-calendar-sync', color: MODULE_AUFGABEN_COLOR }
  },
  // AufgabenVorlagen
  { path: '/aufgaben/aufgabenvorlagen/',
    name: 'AufgabenVorlagenList',
    component: AufgabenVorlagenList,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/aufgabenvorlagen/add',
    name: 'AufgabenVorlagenForm',
    component: AufgabenVorlagenForm,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/aufgabenvorlagen/:id',
    name: 'AufgabenVorlagenFormUpdate',
    component: AufgabenVorlagenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/:modul/:model/:objectId/aufgabenvorlagen/add',
    name: 'AufgabenVorlagenObjektForm',
    component: AufgabenVorlagenForm,
    props: true,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  // AufgabenFeiertage
  { path: '/aufgaben/aufgabenfeiertage/',
    name: 'AufgabenFeiertageList',
    component: AufgabenFeiertageList,
    meta: { icon: 'mdi-calendar-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/aufgabenfeiertage/add',
    name: 'AufgabenFeiertageForm',
    component: AufgabenFeiertageForm,
    meta: { icon: 'mdi-calendar-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/aufgabenfeiertage/:id',
    name: 'AufgabenFeiertageFormUpdate',
    component: AufgabenFeiertageForm,
    props: updateFormProps,
    meta: { icon: 'mdi-calendar-outline', color: MODULE_AUFGABEN_COLOR }
  },
  // Checklisten
  { path: '/aufgaben/checklisten/',
    name: 'ChecklistenList',
    component: ChecklistenList,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/checklisten/add',
    name: 'ChecklistenForm',
    component: ChecklistenForm,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/checklisten/:id',
    name: 'ChecklistenFormUpdate',
    component: ChecklistenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/checklisten/:id/checkpunkte/',
    name: 'CheckpunkteList',
    component: CheckpunkteList,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/checklisten/:objectId/checkpunkte/add',
    name: 'CheckpunkteForm',
    component: CheckpunkteForm,
    props: updateFormObjectProps,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/checklisten/:objectId/checkpunkte/:id',
    name: 'CheckpunkteFormUpdate',
    component: CheckpunkteForm,
    props: updateFormProps,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/checkpunkte/:objectId/checkpunktwerte/add',
    name: 'CheckpunktWerteForm',
    component: CheckpunktWerteForm,
    props: true,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  },
  { path: '/aufgaben/checkpunktwerte/:id',
    name: 'CheckpunktWerteFormUpdate',
    component: CheckpunktWerteForm,
    props: updateFormProps,
    meta: { icon: 'mdi-checkbox-marked-outline', color: MODULE_AUFGABEN_COLOR }
  }
]

export default routes
