import { v4 as uuidv4 } from 'uuid'
import { defineStore } from 'pinia'
import { usePermissionsStore } from '@/stores/permissions'
import apiStorage from '@/utils/apiStorage'

export interface NavigationItem {
  text: string
  uniqueName?: string
  icon?: RegisteredIcon | null
  color?: string
  path?: string
  url?: string
  action?: () => void
  replace?: boolean
  number?: string
  target?: string
  expanded?: boolean
  disabled?: boolean
  addurl?: string
  disableTranslation?: boolean
  permissionName?: string
  needsWritePermission?: boolean
  children?: NavigationItem[]
}

export interface OptionalNavigationItem extends NavigationItem {
  name: string
  badge?: string | null
  action?: () => void
  additionalAction?: (() => void) | null
  visibleFullPath: string
  replace?: boolean
  order: number
  children?: OptionalNavigationItem[]
}

export interface NavigationDivider {
  divider: boolean
}

export type NavigationPosition = {
  x: number
  y: number
  count: number
}

export enum FavoritenType {
  Path = 1,
  Url,
}

export interface FavoritenItem {
  id?: string
  type: FavoritenType
  text: string
  icon?: RegisteredIcon | null
  image?: string
  color?: string
  group?: string
  path: string
}

export type Navigation =
  | { type: 'item' } & NavigationItem
  | { type: 'divider' } & NavigationDivider

export type OptionalNavigation =
  | { type: 'item' } & OptionalNavigationItem
  | { type: 'divider' } & NavigationDivider

type NavigationState = {
  //Navigation
  navigationVisible: boolean
  navigationItems: Navigation[]
  optionalNavigationItems: OptionalNavigation[]
  //Favoriten
  favoritenItems: FavoritenItem[]
  favoritenItemsLoading: boolean
  favoritenExpanded: boolean
  favoritenExpandedLoading: boolean
  favoritenGroupsExpanded: Record<string, boolean>
  favoritenGroupsExpandedLoading: boolean
  //LastPosition
  browserBackForwardUsed: boolean
  lastPosition: Record<string, NavigationPosition>
}

export const useNavigationStore = defineStore({
  id: 'navigation',
  state: (): NavigationState => ({
    //Navigation
    navigationVisible: true,
    navigationItems: [],
    optionalNavigationItems: [],
    //Favoriten
    favoritenItems: [],
    favoritenItemsLoading: false,
    favoritenExpanded: true,
    favoritenExpandedLoading: false,
    favoritenGroupsExpanded: {},
    favoritenGroupsExpandedLoading: false,
    //LastPosition
    lastPosition: {},
    browserBackForwardUsed: false,
  }),
  actions: {
    //Navigation
    setNavigationVisible (payload: boolean) {
      this.navigationVisible = payload
    },
    replaceNavigationItems (payload: NavigationItem[]) {
      const permissionsStore = usePermissionsStore()

      const navigationItems: (NavigationItem & { type: 'item' })[] = []
      for (const navigationItem of payload) {
        if (navigationItem.permissionName) {
          const permission = permissionsStore.hasViewPermission(navigationItem.permissionName)
          if (!permission) {
            continue
          }
          if (navigationItem.needsWritePermission) {
            const permission = permissionsStore.hasChangePermission(navigationItem.permissionName)
            if (!permission) {
              continue
            }
          }
        }
        const children: NavigationItem[] = []
        if (navigationItem.children) {
          for (const child of navigationItem.children) {
            const subChildren = [] as NavigationItem[]
            if (child.children) {
              for (const subChild of child.children) {
                if (subChild.permissionName) {
                  let permission = permissionsStore.hasViewPermission(subChild.permissionName)
                  if (subChild.needsWritePermission) {
                    permission = permissionsStore.hasChangePermission(subChild.permissionName)
                  }
                  if (permission) {
                    subChildren.push(subChild)
                  }
                } else {
                  subChildren.push(subChild)
                }
              }
              child.children = subChildren
              if (subChildren.length === 0) {
                continue
              }
              children.push(child)
            } else {
              if (child.permissionName) {
                let permission = permissionsStore.hasViewPermission(child.permissionName)
                if (child.needsWritePermission) {
                  permission = permissionsStore.hasChangePermission(child.permissionName)
                }
                if (permission) {
                  children.push(child)
                }
              } else {
                children.push(child)
              }
            }
          }
          navigationItem.children = children
          if (children.length === 0) {
            continue
          }
        }
        navigationItems.push({ type: 'item', ...navigationItem })
      }
      this.navigationItems = navigationItems
    },
    addOptionalNavigationItem (payload: OptionalNavigationItem) {
      const index = this.optionalNavigationItems.findIndex(
        function (el) {
          return (el as OptionalNavigationItem).name === payload.name
        })
      if (index === -1) {
        if ('order' in payload) {
          this.optionalNavigationItems.splice(payload.order, 0, { type: 'item', ...payload })
        } else {
          this.optionalNavigationItems.push(payload)
        }
      } else {
        this.optionalNavigationItems[index] = { type: 'item', ...payload }
      }
    },
    removeOptionalNavigationItem (payload: { uniqueName: string }) {
      this.optionalNavigationItems = this.optionalNavigationItems.filter(item => (item as OptionalNavigationItem).name !== payload.uniqueName)
    },
    clearOptionalNavigationItems () {
      this.optionalNavigationItems = []
    },
    setOptionalNavigationItemData (payload: { name: string } & Partial<OptionalNavigationItem>) {
      const index = this.optionalNavigationItems.findIndex(
        function (el) {
          return (el as OptionalNavigationItem).name === payload.name
        })
      if (index !== -1) {
        Object.keys(payload).forEach(key => {
          if (payload[key]) {
            this.optionalNavigationItems[index][key] = payload[key]
          }
        })
      }
    },
    expandNavigationItem ({ navigationItem, status }: { navigationItem: NavigationItem, status: boolean }) {
      this.navigationItems.some((element, index) => {
        if ((element as NavigationItem).uniqueName === navigationItem.uniqueName) {
          (element as NavigationItem).expanded = status
          return true
        }
        if (Array.isArray((element as NavigationItem).children)) {
          ((this.navigationItems[index] as NavigationItem).children as NavigationItem[]).some((subElement, subIndex) => {
            if (subElement.uniqueName === navigationItem.uniqueName) {
              ((this.navigationItems[index] as NavigationItem).children as NavigationItem[])[subIndex].expanded = status
              return true
            }
          })
        }
      })
      this.optionalNavigationItems.some((element, index) => {
        if ((element as OptionalNavigationItem).uniqueName === navigationItem.uniqueName) {
          (element as OptionalNavigationItem).expanded = status
          return true
        }
        if (Array.isArray((element as OptionalNavigationItem).children)) {
          ((this.optionalNavigationItems[index] as OptionalNavigationItem).children as OptionalNavigationItem[]).some((subElement, subIndex) => {
            if (subElement.uniqueName === navigationItem.uniqueName) {
              ((this.optionalNavigationItems[index] as OptionalNavigationItem).children as OptionalNavigationItem[])[subIndex].expanded = status
              return true
            }
          })
        }
      })
      this.optionalNavigationItems.some((element, index) => {
        if ((element as OptionalNavigationItem).uniqueName === navigationItem.uniqueName) {
          (element as OptionalNavigationItem).expanded = status
          return true
        }
        if (Array.isArray((element as OptionalNavigationItem).children)) {
          ((this.optionalNavigationItems[index] as OptionalNavigationItem).children as OptionalNavigationItem[]).some((subElement, subIndex) => {
            if (subElement.uniqueName === navigationItem.uniqueName) {
              ((this.optionalNavigationItems[index] as OptionalNavigationItem).children as OptionalNavigationItem[])[subIndex].expanded = status
              return true
            }
          })
        }
      })
    },
    //Navigation - Berichte
    addBerichtInhaltsverzeichnisItems (payload: OptionalNavigationItem) {
      const index = this.optionalNavigationItems.findIndex(
        function (el) {
          return (el as OptionalNavigationItem).name === 'Inhaltsverzeichnis'
        })
      if (index === -1) {
        this.optionalNavigationItems.push({ type: 'item', ...payload })
      } else {
        // Hauptelement bereits vorhanden
        this.optionalNavigationItems[index] = { type: 'item', ...payload }
      }
    },
    replaceBerichtInhaltsverzeichnisItemText (payload: { uniqueName: string, text: string }) {
      const index = this.optionalNavigationItems.findIndex(
        function (el) {
          return (el as OptionalNavigationItem).name === 'Inhaltsverzeichnis'
        })
      if (index >= 0) {
        // Hauptelement bereits vorhanden
        const subIndex = (this.optionalNavigationItems[index] as OptionalNavigationItem).children?.findIndex(
          function (el) {
            return el.uniqueName === payload.uniqueName
          })
        if (subIndex && subIndex >= 0) {
          ((this.optionalNavigationItems[index] as OptionalNavigationItem).children as OptionalNavigationItem[])[subIndex].text = payload.text
        }
      }
    },
    removeBerichtInhaltsverzeichnis () {
      const index = this.optionalNavigationItems.findIndex(
        function (el) {
          return (el as OptionalNavigationItem).name === 'Inhaltsverzeichnis'
        })
      if (index >= 0 && this.optionalNavigationItems[index]) {
        this.optionalNavigationItems.splice(index, 1)
      }
    },
    // Favoriten
    async setFavoritenItems (payload: FavoritenItem[], saveToBackend = true) {
      this.favoritenItems = payload
      if (saveToBackend) {
        await apiStorage.setItem('favoriten', payload)
      }
    },
    async loadFavoritenItems () {
      this.favoritenItemsLoading = true
      try {
        let res: any = await apiStorage.getItem('favoriten', false)
        if (!res) {
          res = []
          await this.setFavoritenItems(res, false)
        } else {
          //damit keine Endlosschleife entsteht
          this.favoritenItems = res
        }
      } finally {
        this.favoritenItemsLoading = false
      }
    },
    async addFavoritenItem (payload: FavoritenItem) {
      payload.id = uuidv4()
      if (payload.group === '') {
        payload.group = undefined
      }
      let res: FavoritenItem[] = JSON.parse(JSON.stringify(await apiStorage.getItem('favoriten', false)))
      if (!res) {
        res = []
      }
      res.push(payload)
      await this.setFavoritenItems(res)
    },
    async editFavoritenItem (payload: FavoritenItem) {
      let res: FavoritenItem[] = JSON.parse(JSON.stringify(await apiStorage.getItem('favoriten', false)))
      if (!res) {
        res = []
      }
      const index = res.findIndex(el => el.id === payload.id)
      if (index >= 0) {
        res[index] = payload
      }
      await this.setFavoritenItems(res)
    },
    async removeFavoritenItem (id: string) {
      this.favoritenItems = this.favoritenItems.filter(item => item.id !== id)
      await apiStorage.setItem('favoriten', this.favoritenItems)
    },
    async setFavoritenExpanded (payload: boolean, saveToBackend = true) {
      this.favoritenExpanded = payload
      if (saveToBackend) {
        await apiStorage.setItem('favoritenexpanded', payload)
      }
    },
    async loadFavoritenExpanded () {
      this.favoritenExpandedLoading = true
      try {
        let res: any = await apiStorage.getItem('favoritenexpanded', false)
        if (!res) {
          res = false
        }
        await this.setFavoritenExpanded(res, false)
      } finally {
        this.favoritenExpandedLoading = false
      }
    },
    async setFavoritenGroupsExpanded (payload: Record<string, boolean>, saveToBackend = true) {
      this.favoritenGroupsExpanded = payload
      if (saveToBackend) {
        return apiStorage.setItem('favoritengroupsexpanded', payload)
      }
    },
    async loadFavoritenGroupsExpanded () {
      this.favoritenGroupsExpandedLoading = true
      try {
        let res: any = await apiStorage.getItem('favoritengroupsexpanded', false)
        if (!res) {
          res = {}
        }
        await this.setFavoritenGroupsExpanded(res, false)
      } finally {
        this.favoritenGroupsExpandedLoading = false
      }
    },
    // Last Position
    setLastPosition ({ url, data }: { url: string, data: NavigationPosition }) {
      this.lastPosition[url] = data
    },
    removeLastPosition (url: string) {
      delete this.lastPosition[url]
    },
    setBrowserBackForwardUsed (payload: boolean) {
      this.browserBackForwardUsed = payload
    },
  },
  getters: {
    getLastPosition () {
      return (url: string): NavigationPosition | null => {
        return this.lastPosition[url] || null
      }
    },
    getFavoritenItemByPath () {
      return (path: string): FavoritenItem | null => {
        return this.favoritenItems.find(item => item.path === path) || null
      }
    }
  },
})