import logging from '@/utils/logging'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import router from '@/router/index'
import { useI18nStore } from '@/stores/i18n'
import { useTokenValidationStore } from '@/stores/tokenValidation'

export type ApiListResponse<T> = {
  count: number
  next: string | null
  previous: string | null
  results: T[]
}

export default {
  setDefaultHeaders (redirectToLogin = true, sendAuthToken = true) {
    axios.defaults.baseURL = this.getBaseURL()
    if (sendAuthToken) {
      const tokenValidationStore = useTokenValidationStore()
      const token = localStorage.getItem('token')
      if (token) {
        axios.defaults.headers.common['Authorization'] = token
      }
      const UNAUTHORIZED = 401
      axios.interceptors.response.use(
        response => {
          tokenValidationStore.incrementApiRequestCount()
          return response
        },
        error => {
          logging.warn(error)
          if (redirectToLogin && error && Object.keys(error).includes('response')) {
            if (error.response) {
              const {status} = error.response
              if (status === UNAUTHORIZED) {
                if (router.currentRoute.value.name !== 'Login') {
                  if (tokenValidationStore.getApiRequestCount === 0) {
                    sessionStorage.setItem('redirect', router.currentRoute.value.path)
                    window.location.href = `/`
                  } else {
                    tokenValidationStore.setTokenValid(false)
                  }
                }
              }
            }
          }
          return Promise.reject(error)
        }
      )
    } else {
      // Lösche Authorization wenn keine Authentifizierung gewünscht ist (anonymer Request)
      delete axios.defaults.headers.common['Authorization']
    }
  },
  setContentLanguageHeader (lang?: string) {
    const i18nStore = useI18nStore()
    let contentLanguage = lang
    if (!lang) {
      contentLanguage = i18nStore.getContentLanguage
    }
    if (contentLanguage) {
      axios.defaults.headers.common['lang'] = contentLanguage
    }
  },
  setUiLanguageHeader () {
    const i18nStore = useI18nStore()
    axios.defaults.headers.common['lang'] = i18nStore.getUiLanguage
  },
  setUrlTrailingSlash(url: string) {
    url = url.replace(`${import.meta.env.VITE_APP_BACKEND_URL}`, '')
    url = url.replace(`${import.meta.env.VITE_APP_BACKEND_API}`, '')
    if (url.includes('?')) {
      return url
    }
    if (url.includes('.')) {
      return url
    }
    if (!url.endsWith('/')) {
      return url + '/'
    }
    return url
  },
  checkExpireRefresh() {
    const d = new Date()
    const currDate = `${d.getDate()}-${d.getMonth()+1}-${d.getFullYear()}`
    const programDate = localStorage.getItem('programDate')
    if (programDate !== currDate) {
      logging.info('Force Reload')
      window.location.reload()
    }
  },
  get<T=any> (url: string, config?: AxiosRequestConfig, redirectToLogin = true, sendAuthToken = true): Promise<T> {
    this.setDefaultHeaders(redirectToLogin, sendAuthToken)
    this.setContentLanguageHeader()
    this.checkExpireRefresh()
    const requestUrl = this.setUrlTrailingSlash(url)
    return axios.get<T>(requestUrl, config)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  },
  getArrayBuffer (url: string, redirectToLogin = true, sendAuthToken = true): Promise<AxiosResponse<ArrayBuffer>> {
    this.setDefaultHeaders(redirectToLogin, sendAuthToken)
    this.setContentLanguageHeader()
    this.checkExpireRefresh()
    const requestUrl = this.setUrlTrailingSlash(url)
    return axios.get(requestUrl, { responseType: 'arraybuffer' })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getBlob (url: string, redirectToLogin = true, sendAuthToken = true): Promise<AxiosResponse<Blob>> {
    this.setDefaultHeaders(redirectToLogin, sendAuthToken)
    this.setContentLanguageHeader()
    this.checkExpireRefresh()
    const requestUrl = this.setUrlTrailingSlash(url)
    return axios.get(requestUrl, { responseType: 'blob' })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  post<T=any> (url: string, data?: any, lang?: string, redirectToLogin = true, sendAuthToken = true): Promise<T> {
    this.setDefaultHeaders(redirectToLogin, sendAuthToken)
    this.setContentLanguageHeader(lang)
    const requestUrl = this.setUrlTrailingSlash(url)
    return axios.post<T>(requestUrl, data, { headers: {'Content-Type': 'application/json'} })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  },
  /**
   * Post-Request aufs AsiX-Backend mit FormData
   * @param url
   * @param data
   * @param resolveFullResponse - Soll der Promise auf die volle Antwort (mit Status, Header etc.)
   * resolven oder auf response.data
   */
  postFormData (url: string, data: any, resolveFullResponse=false) {
    this.setDefaultHeaders()
    this.setContentLanguageHeader()
    const requestUrl = this.setUrlTrailingSlash(url)
    return axios.post(requestUrl, data, { headers: {'Content-Type': 'multipart/form-data'} })
      .then((response) => Promise.resolve(resolveFullResponse ? response : response.data))
      .catch((error) => Promise.reject(error))
  },
  postBlobExternal (url: string, data: any) {
    return axios.post(url, data, { responseType: 'blob' })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  put (url: string, redirectToLogin = true, sendAuthToken = true) {
    this.setDefaultHeaders(redirectToLogin, sendAuthToken)
    this.setContentLanguageHeader()
    const requestUrl = this.setUrlTrailingSlash(url)
    return axios.put(requestUrl)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  patch<T=any> (url: string, data: any, redirectToLogin = true, sendAuthToken = true): Promise<T> {
    this.setDefaultHeaders(redirectToLogin, sendAuthToken)
    this.setContentLanguageHeader()
    const requestUrl = this.setUrlTrailingSlash(url)
    return axios.patch(requestUrl, data)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  },
  patchFormData (url: string, data: any) {
    this.setDefaultHeaders()
    this.setContentLanguageHeader()
    const requestUrl = this.setUrlTrailingSlash(url)
    return axios.patch(requestUrl, data, {headers: {'Content-Type': 'multipart/form-data'}})
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  },
  delete (url: string, data: any = null, redirectToLogin = true, sendAuthToken = true) {
    const requestUrl = this.setUrlTrailingSlash(url)
    if (!data) {
      this.setDefaultHeaders(redirectToLogin, sendAuthToken)
      this.setContentLanguageHeader()
      return axios.delete(requestUrl)
        .then((response) => Promise.resolve(response))
        .catch((error) => {
          if (error.response.status === 404) {
            // Kein Fehler, wenn objekt bereits gelöscht ist
            logging.debug('Objekt wurde bereits gelöscht')
            return Promise.resolve(error)
          }
          return Promise.reject(error)
        })
    } else {
      this.setDefaultHeaders(redirectToLogin, sendAuthToken)
      this.setContentLanguageHeader()
      const config = { headers: {'Content-Type': 'application/json'}, data: data}
      return axios.delete(requestUrl, config)
        .then((response) => Promise.resolve(response))
        .catch((error) => {
          if (error.response.status === 404) {
            // Kein Fehler, wenn objekt bereits gelöscht ist
            logging.debug('Objekt wurde bereits gelöscht')
            return Promise.resolve(error)
          }
          Promise.reject(error)
        })
    }
  },
  options<T=any> (url: string, redirectToLogin = true, sendAuthToken = true): Promise<T> {
    this.setDefaultHeaders(redirectToLogin, sendAuthToken)
    this.setUiLanguageHeader()
    this.checkExpireRefresh()
    const requestUrl = this.setUrlTrailingSlash(url)
    return axios.options<T>(requestUrl)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  },
  getBaseURL() {
    return `${import.meta.env.VITE_APP_BACKEND_URL}${import.meta.env.VITE_APP_BACKEND_API}`
  }
}
