import { RouteRecordRaw } from 'vue-router'
import { MODULE_STAMMDATEN_COLOR } from '@/modules/moduleConstants'

// Kosten
const AsiXConfigForm = () => import('@/modules/konsole/AsiXConfigForm.vue')
const AsiXConfigList = () => import('@/modules/konsole/AsiXConfigList.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const routes: RouteRecordRaw[] = [
  // AsiXConfig
  { path: '/konsole/asixconfig/',
    name: 'AsiXConfigList',
    component: AsiXConfigList,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/konsole/asixconfig/add',
    name: 'AsiXConfigForm',
    component: AsiXConfigForm,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
  { path: '/konsole/asixconfig/:id',
    name: 'AsiXConfigFormpdate',
    component: AsiXConfigForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_STAMMDATEN_COLOR }
  },
]

export default routes
