import { defineStore } from 'pinia'

export type CacheState = {
  cache: Record<string, Record<string,unknown>|unknown>
}

export const useCacheStore = defineStore({
  id: 'cache',
  state: (): CacheState => ({
    cache: {}
  }),
  actions: {
    setCache ({url, key, value}: {url: string, key?: string, value: unknown}) {
      if(this.cache[url] === undefined) {
        this.cache[url] = {}
      }
      if(key) {
        (this.cache[url] as Record<string, unknown>)[key] = value
      } else {
        this.cache[url] = value
      }
    },
    clearCache () {
      this.cache = {}
    }
  },
  getters: {
    getCache () {
      return (url: string, key?: string): unknown | undefined => {
        if (key !== undefined) {
          return this.cache[url]?.[key]
        } else {
          return this.cache[url]
        }
      }
    }
  }
})